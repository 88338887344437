import React, {useEffect, useState } from "react"
import axios from "axios";
import { Link, useNavigate, useParams } from "react-router-dom";

import moni from 'F:/projet/first/src/components/image/moni.png';
import croix from 'F:/projet/first/src/components/image/croix.png'
import moment from "moment";
import Cookies from 'js-cookie';
import secre from 'F:/projet/first/src/components/image/images.png'
import Modal from "./popup";
import ErrorModal from "./errorpopup";
export default function Profil2(){

         const headers = {
            'Content-Type': 'application/json',
            'Access-Control-Allow-Origin': 'https://gestauto.seg.solutions',
            'credentials': 'include',
          };
        
         const [lu,setLu] = useState(Cookies.get('token'))
          const [tok, setTok]= useState(Cookies.get('token'))
          const [titre,setTitre]= useState(Cookies.get('titre'))
   
          const [gestauto,setGestauto]= useState(Cookies.get('gestauto'))
         const [le, setLe]= useState('')
         const [name, setName]= useState('')
         const [prenom, setPrenom]= useState('')
         const [username,setUsername ]= useState('')
         const [password,setPassword ]= useState('')
         const [total, setTotal]= useState('')
         const [contact, setContact]= useState('')
         const [dure, setDure]= useState('')
         const [langue, setLangue]= useState('')
         const [sexe, setSexe]= useState('')
         const [adresse, setAdresse]= useState('')
         const [age,setAge]= useState('')
         const [montant, setMontant]= useState('')
         const [statut, setStatut]= useState('')
         const [permis, setPermis]= useState('')
         const [date, setDate]= useState('')
         const [dateRetrait, setDateRetrait]= useState('')
         const [nombre, setNombre]= useState('')
         const [nombre2, setNombre2]= useState('')
         const {id}= useParams();
         const [mie, setMie]= useState(Cookies.get('mie'))
         const navigate = useNavigate();
         const [payement, setPayement]= useState([])
         const [certain, setCertain]= useState('')
         const [categorie,setCategorie]= useState('')
         const [image, setImage]= useState([])
         useEffect(()=>{
            axios.post('https://gestauto-56bfcbe4b876.herokuapp.com/cer' ,{gestauto,titre,mie,tok}, { headers})
            .then(res => 
                {
                    setCertain(res.data.message);
        
                })
            .catch(err => console.log(err));
        }, [])
        
            if(certain=='denied'){
                Cookies.remove('token')
                Cookies.remove('mie')
                Cookies.remove('titre')
                Cookies.remove('gestauto')
                window.location.reload();
                navigate('/')
            }
        
  
  useEffect(()=>{
    if(!Cookies.get('token')){
       navigate('/')
    }
}, [])

const logout = () =>{

Cookies.remove('token')
navigate('/')
window.location.reload();
}

if(tok!= lu){
Cookies.remove('token')
window.location.reload();
navigate('/')

}

useEffect(()=>{
    axios.post('https://gestauto-56bfcbe4b876.herokuapp.com/setToken' ,{gestauto,titre}, { headers})
    .then(res => 
        {setTok(res.data[0].token)
            

        })
    .catch(err => console.log(err));
}, [])
     
         const [candidat, setCandidat] = useState([])
         useEffect(()=>{
             axios.get('https://gestauto-56bfcbe4b876.herokuapp.com/update/'+id, { headers})
             .then(res => { 
                 setLe(res.data[0].id)
                 setName(res.data[0].nom)
                 setPrenom(res.data[0].prenom)
                 setContact(res.data[0].contact)
                 setSexe(res.data[0].sexe)
                 setAdresse(res.data[0].adresse)
                 setAge(res.data[0].age)
                 setMontant(res.data[0].montant_payer)
                 setStatut(res.data[0].statut_payement)
                 setDate(res.data[0].date)
                 setPermis(res.data[0].statut_retrait_permis)
                 setDateRetrait(res.data[0].date_retrait)
                 setCandidat(res.data)
                 setTotal(res.data[0].montant_total)
                 setUsername(res.data[0].user_name)
                 setPassword(res.data[0].password)
                 setDure(res.data[0].dure)
                 setLangue(res.data[0].langue)
                 setCategorie(res.data[0].categorie)
                 setImage(res.data[0].image)
             }
                 
                 
                 
            
             
                
     
     
                 
                 )
             .catch(err => console.log(err));
     
         
         }, [])
         useEffect(()=>{
         axios.post('https://gestauto-56bfcbe4b876.herokuapp.com/paye',{name,prenom}, { headers})
         .then(res => setPayement(res.data)
     ).catch(err => console.log(err))
    }, [])  
     let totalPaye=0
     const first=montant
     
     const[modal, setModal]= useState(false) 
const toggleModal = ()=> {
    setModal(!modal)
}
     
     payement.map((data, i)=> ( 
                     
         totalPaye=totalPaye+data.montant    
     
     ))
       
     
     
        useEffect(()=>{
        axios.post('https://gestauto-56bfcbe4b876.herokuapp.com/nombre',{le}, { headers})
        .then(res => setNombre(res.data)
     ).catch(err => console.log(err))
       
     axios.post('https://gestauto-56bfcbe4b876.herokuapp.com/nombre2',{le}, { headers})
        .then(res => setNombre2(res.data)
     ).catch(err => console.log(err))
    }, [])
var permisInfo
var laDate
if(permis=='oui'){
  permisInfo='Retiré'
  laDate=<div className="adro">
  <div >LE</div> <div>{moment(dateRetrait).utc(1).format('DD-MM-YYYY')}</div>
  </div>
}else{
    permisInfo='Non retiré'
}

let cata 
if(categorie){
  cata=<> <div className="adro">
  <div >Categorie</div> <div>Permis {categorie}</div>
  </div><br/></>
}else{
  cata= ''
}



    
    return(


        <>
        <div className="pofille">
       <div className="box">
        <br/>
        
       <div className="playProfil"> 
       <div className="image-container8">
<img src={image ? `${image}` : (sexe === 'Masculin' ? moni : secre)} alt="Candidat" className="profile-image" />

          </div>
                   
<Link  onClick={toggleModal} >     <h5 className="osi">  {name} {prenom} </h5></Link>
{modal && (<div className='modal'>
    <div className='overlay'>
        
    </div>
    <div className='modal-content'>
        <div> <h3 className="useName">USERNAME :</h3> {username}<br/>
        <h3 className="useName">PASSWORD :</h3> {password}
        </div>
      <Link onClick={toggleModal}>  <img src={croix} className='close-modal'/></Link>
    </div>
</div>)}
                   </div>
                   
                   <br/>
               
                   <div className="adro">
                  <div > SEXE</div> <div>{sexe}</div>
                  </div>
                  <br/>
                 
                   <div className="adro">

                 <div className="tes">  ADRESSE</div> <div className="tes">{adresse}</div>
                   </div>
                   <br/>
                   <div className="adro">
                  <div > CONTACT</div> <div>{contact}</div>
                  </div>
                  <br/>
                  
                  <div className="adro">
                  <div > INSCRIT</div> <div>{moment(date).utc(1).format('DD-MM-YYYY')}</div>
                  </div>
                  <div></div>
                  <br/>
                  <div className="adro">
                  <div > INSCRIT</div> <div>{moment(date).utc(1).format('DD-MM-YYYY')}</div>
                  </div>
                  <br/>
                  {cata}
                  
                  
                  <div className="adro">
                  <div > Duré de la formation</div> <div>{dure.substring(0,2)} mois</div>
                  </div>
                  <br/>
                  <div className="adro">
                  <div > Langue</div> <div>{langue}</div>
                  </div>
                  <br/>

                  
                  <div className="adro">
                  <div >PERMIS </div> <div>{permisInfo}</div>
                  </div>
                  <br/>
                  {laDate}
                  <br/>
                 <div className="cru">
                  <button className="retrait">PAYER</button> 
                  
                  <button className="retrait" onClick={logout}> Deconnexion</button>
                  </div>
                   </div>
                   </div>
        </>
    )
}