import React, {useEffect, useState } from "react";
import axios from "axios";
import { Link, useNavigate, useParams ,NavLink,  useLocation} from "react-router-dom";


import Profil from 'F:/projet/first/src/components/profil';
import Option from 'F:/projet/first/src/components/option';
import Loader from "../components/loader";

import moni from 'F:/projet/first/src/components/image/moni.png';
import log from 'F:/projet/first/src/components/image/log.png';
import Footer from '../components/footer';


import Cookies from 'js-cookie';
import { sidedataDir } from 'F:/projet/first/src/components/sidedataDir'
import * as FaIcons from "react-icons/fa";
import * as AiIcons from "react-icons/ai";
import { IconContext } from "react-icons";
import logo from 'F:/projet/first/src/components/image/logo4.jpg'

function PageCandidatDir(){
    const headers = {
        'Content-Type': 'application/json',
        'Access-Control-Allow-Origin': 'https://gestauto.seg.solutions',
        'credentials': 'include',
      };
      const [error, setError] = useState(null);
    const [sidebar, setSidebar]= useState(false)
    const showSidebar = () => setSidebar(!sidebar)
    const [lu,setLu] = useState(Cookies.get('token'))
    const [tok, setTok]= useState('')

    const [largeurEcran, setLargeurEcran] = useState(window.innerWidth);
    const [titre,setTitre]= useState(Cookies.get('titre'))
   
    const [gestauto,setGestauto]= useState(Cookies.get('gestauto'))
    const [loader, setLoader]= useState(true)
    const [old, setOld]= useState('')
    const [recup, setRecup]= useState('')
    const [new1, setNew1]= useState('')
    const [new2, setNew2]= useState('')
    const [info1, setInfo1]= useState('')
    const [info2, setInfo2]= useState('')
    const mettreAJourLargeurEcran = () => {
        setLargeurEcran(window.innerWidth);
    
      };

      if(tok!= ''){
        if(tok!= lu){
            Cookies.remove('token')
            Cookies.remove('autoa')
            Cookies.remove('titre')
            Cookies.remove('gestauto')
            window.location.reload();
            navigate('/')

        }
    }

    var navigate= useNavigate()
    useEffect(()=>{
         if(!Cookies.get('token')){
            navigate('/')
         }
    }, [])
 
    const logout = () =>{
        Cookies.remove('token')
        Cookies.remove('autoa')
        Cookies.remove('titre')
        Cookies.remove('gestauto')
        Cookies.remove('default')
        Cookies.remove('default2')
        Cookies.remove('defaultCode')
        Cookies.remove('defaultCode2')
        window.location.reload();
       navigate('/')
    }
    useEffect(()=>{
        if(!Cookies.get('token')){
           navigate('/')
        }
   }, [])

useEffect(() => {
        
    window.addEventListener('resize', mettreAJourLargeurEcran);

   
    return () => {
      window.removeEventListener('resize', mettreAJourLargeurEcran);
      
    };
   
  }, []);
// Recuperer token
useEffect(()=>{
    axios.post('https://gestauto-56bfcbe4b876.herokuapp.com/setToken',{gestauto,titre}, { headers})
    .then(res => 
        {setTok(res.data[0].token)
        

        })
    .catch(err => console.log(err));
}, [])
//nav en haut

    const[profil, setProfil]= useState(false)

    const showProfil = () => setProfil(!profil)

   
    const [secretaire, setSecretaire] = useState([])
    useEffect(()=>{
        axios.get('https://gestauto-56bfcbe4b876.herokuapp.com/navbar?id='+gestauto+'&titre='+titre+'', { headers})
        .then(res => setSecretaire(res.data))
        .catch(err => console.log(err));
    }, [])
    

    var o


    
    const [le, setLe]= useState(0)
    const [name, setName]= useState('')
    const [prenom, setPrenom]= useState('')
    const [total, setTotal]= useState('')
    const [contact, setContact]= useState('')
    const [sexe, setSexe]= useState('')
    const [adresse, setAdresse]= useState('')
    const [age,setAge]= useState('')
    const [montant, setMontant]= useState('')
    const [statut, setStatut]= useState('')
    const [date, setDate]= useState('')
    const [nombre, setNombre]= useState('')
    const [nombre2, setNombre2]= useState('')
    const {id}= useParams();
    const [payement, setPayement]= useState([])
    const [controleId, setControleId]= useState('')
 const [candidat, setCandidat] = useState([])
    useEffect(()=>{
        axios.get('https://gestauto-56bfcbe4b876.herokuapp.com/update/'+id, { headers})
        .then(res => { 
            setLe(res.data[0].id)
            setName(res.data[0].nom)
            setPrenom(res.data[0].prenom)
            setContact(res.data[0].contact)
            setSexe(res.data[0].sexe)
            setAdresse(res.data[0].adresse)
            setAge(res.data[0].age)
            setMontant(res.data[0].montant_payer)
            setStatut(res.data[0].statut_payement)
            setDate(res.data[0].date)
            setCandidat(res.data)
            setTotal(res.data[0].montant_total)
            setControleId(res.data[0].id_ecole)
        }
            
      
            )
        .catch(err => console.log(err));

    
    }, [])
    useEffect(()=>{
        axios.post('https://gestauto-56bfcbe4b876.herokuapp.com/paye',{name,prenom}, { headers})
        .then(res => setPayement(res.data)
    ).catch(err => console.log(err))
    
}, [])

useEffect(() => {
   
    setTimeout(() => {
      setLoader(false); 
    }, 3000); 
  }, []);
   
    
let totalPaye=0
const first=montant



payement.map((data, i)=> ( 
                
    totalPaye=totalPaye+data.montant    

))
 

   useEffect(()=>{
    axios.post('https://gestauto-56bfcbe4b876.herokuapp.com/nombre',{le}, { headers})
    .then(res => setNombre(res.data)
 ).catch(err => console.log(err))
   
 axios.post('https://gestauto-56bfcbe4b876.herokuapp.com/nombre2',{le}, { headers})
    .then(res => setNombre2(res.data)
 ).catch(err => console.log(err))
}, [])

   
  
      
      
var o
       o=controleId





//modification password



const[valid, setValid]= useState('')
              
const[valid2, setValid2]= useState('')
const[supMod, setSupMod]= useState(false) 

const toggleSup = ()=> {
  
 
  setSupMod(!supMod)
  setValid('')
 
}


if(valid!= ''){
 toggleSup()
 


} 



              

const[supMod2, setSupMod2]= useState(false) 

const toggleSup2 = ()=> {
  
 
  setSupMod2(!supMod2)
  setValid2('')
  
}


if(valid2!= ''){
 toggleSup2()
 


} 




function handleModf(event){
    const id= gestauto
    event.preventDefault();
    const currentPassword = secretaire.map((data) => data.password)[0];
    
    if(currentPassword===old){
    
        if(new1===new2){
            setInfo2('')
            axios.put('https://gestauto-56bfcbe4b876.herokuapp.com/upPassword/'+id, {new1,titre}, { headers})
            .then(res => {
                    
        axios.get('https://gestauto-56bfcbe4b876.herokuapp.com/navbar?id='+gestauto+'&titre='+titre+'', { headers })
        .then(res => setSecretaire(res.data))
        .catch(err => console.log(err));
                toggleSup()

            setValid2('on')
            }).catch(err => console.log(err));
        }else{
           
            setInfo2('Incorrecte')
            setTimeout(() => setInfo2(''), 3000)
        }
    }else{
        setInfo1('Incorrecte')
        setTimeout(() => setInfo1(''), 3000)
    }
}


const [menuVisible, setMenuVisible] = useState(false);

const toggleMenu = () => {
    setMenuVisible(!menuVisible);
  
};



const [showOldPassword, setShowOldPassword] = useState(false);
const [showNewPassword, setShowNewPassword] = useState(false);
const [showConfirmPassword, setShowConfirmPassword] = useState(false);


const toggleOldPasswordVisibility = () => setShowOldPassword(!showOldPassword);
const toggleNewPasswordVisibility = () => setShowNewPassword(!showNewPassword);
const toggleConfirmPasswordVisibility = () => setShowConfirmPassword(!showConfirmPassword);




useEffect(() => {
    const handleClickOutside = (event) => {
        if (menuVisible && !document.querySelector('.carD').contains(event.target)) {
            setMenuVisible(false);
        }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
        document.removeEventListener('mousedown', handleClickOutside);
    };
}, [menuVisible]);

        // ici bar
        var bar 
        const location = useLocation();
        if(largeurEcran>= 1000){
            bar=    <div className="barHaut">
 <div className="lep">
                    
                    
                    <img src={moni} className="user" onClick={showProfil} />
                    
                    {supMod && (<div className='secretModalSup'>
            <div className='secretOverlay'></div>
            <div className='secretModalContent'>
                <div className="secretContainerModal"> 
                    <h3 className="secretFormTitle">MODIFIER MOT DE PASSE</h3>
                    
                    <div className='secretSupD'>
                        <input type="text" name="nom" className="secretInputCmot" id="nom" value={secretaire.map((data) => (data.nom))} readOnly />
                        <input type="text" name="prenom" className="secretInputCmot" id="prenom" value={secretaire.map((data) => (data.prenom))} readOnly />
                    </div>
                    
                    <label htmlFor="oldPassword">Ancien mot de passe</label><br/>
                    <div style={{ position: 'relative' }}>
                        <input type={showOldPassword ? 'text' : 'password'} name="oldPassword" className="secretInputCmot" id="oldPassword" onChange={e => setOld(e.target.value)} />
                        <span onClick={toggleOldPasswordVisibility} style={{ position: 'absolute', right: '10px', top: '50%', transform: 'translateY(-50%)', cursor: 'pointer' }}>
                            <i className={showOldPassword ? 'fas fa-eye-slash' : 'fas fa-eye'}></i>
                        </span>
                    </div>
                    <br/>
                    <h4 className="text-danger">{info1}</h4>
                    <br/>
                    <label htmlFor="newPassword">Nouveau mot de passe</label><br/>
                    <div style={{ position: 'relative' }}>
                        <input type={showNewPassword ? 'text' : 'password'} name="newPassword" className="secretInputCmot" id="newPassword" onChange={e => setNew1(e.target.value)} />
                        <span onClick={toggleNewPasswordVisibility} style={{ position: 'absolute', right: '10px', top: '50%', transform: 'translateY(-50%)', cursor: 'pointer' }}>
                            <i className={showNewPassword ? 'fas fa-eye-slash' : 'fas fa-eye'}></i>
                        </span>
                    </div>
                    <br/>
                    <label htmlFor="confirmPassword">Confirmer mot de passe</label><br/>
                    <div style={{ position: 'relative' }}>
                        <input type={showConfirmPassword ? 'text' : 'password'} name="confirmPassword" className="secretInputCmot" id="confirmPassword" onChange={e => setNew2(e.target.value)} />
                        <span onClick={toggleConfirmPasswordVisibility} style={{ position: 'absolute', right: '10px', top: '50%', transform: 'translateY(-50%)', cursor: 'pointer' }}>
                            <i className={showConfirmPassword ? 'fas fa-eye-slash' : 'fas fa-eye'}></i>
                        </span>
                    </div>
                    <br/>
                    <h4 className="text-danger">{info2}</h4>
                    <br/>
                    <div className="secretSupD">
                        <Link onClick={handleModf}><button className='secretNm1'>Modifier</button></Link>
                        <Link onClick={toggleSup}><button className='secretNm2'>Annuler</button></Link>
                    </div>
                </div>
            </div>
        </div>)}
{supMod2 && (<div className='modal'>
    <div className='overlay'>
        
   
    <div className='modal-content'>
       
      <h2>Mot de passe modifier avec succès</h2>
      <div className='supD'>
     
      <Link onClick={toggleSup2}> <button className='supC' >QUITER</button> </Link>
    
      </div>
     
      </div>
    </div>
   
</div>)}
                    
                    <div className="hero"> 
                     <h3 className="nom">{secretaire.map((data)=>(data.nom))} {secretaire.map((data)=>(data.prenom))}</h3>
                     
                    </div>
       
                    <div class="options">
     <h2 className="form-title2"> {secretaire.map((data)=>(data.nom))} {secretaire.map((data)=>(data.prenom))}</h2>           
    <button class="option-btn" onClick={e => setValid('on')}>Modifier</button>
    <button class="option-btn2" onClick={logout}>Déconnexion</button>
  </div>
       
                    </div>

        <NavLink to={'/accueil2/'+o} style={({isActive})=>{ return{background: isActive? "gray": 'rgb(6, 122, 116)',};} }><button className='pr'> Accueil</button> </NavLink>
<NavLink to={'/candidatDir/'+o} className='dirResult'><button className='pr'> Candidats</button> </NavLink>
    <NavLink to={'/depenseDir/'+o} style={({isActive})=>{ return{background: isActive? "gray": 'rgb(6, 122, 116)',};} }><button className='pr'> Depenses</button> </NavLink>
    
    
    <NavLink to={'/payementDir/'+o} style={({isActive})=>{ return{background: isActive? "gray": 'rgb(6, 122, 116)',};} }><button className='pr'> Recettes</button> </NavLink>
    <NavLink to={'/bilan/'+o} style={({isActive})=>{ return{background: isActive? "gray": 'rgb(6, 122, 116)',};} }><button className='pr'> Bilan Global</button> </NavLink>
    <NavLink to={'/optPresenceDir/'+o} style={({isActive})=>{ return{background: isActive? "gray": 'rgb(6, 122, 116)',};} }><button className='pr'> Presence</button> </NavLink>
    <NavLink to={'/resultatDir/'+o} style={({isActive})=>{ return{background: isActive? "gray": 'rgb(6, 122, 116)',};} }><button className='pr'> Resultats</button> </NavLink>
    
    <Link onClick={logout} className="link">
<img src={log} className="am" />
<div className="deco">DECONNEXION</div>

</Link> 
    
    

</div>

        }else{
            bar=  <IconContext.Provider value={{color:'#fff'}} >
                         
            <div className="icon">
         <div>
         &nbsp;&nbsp;&nbsp;
         <img src={logo} className="userLog"  /> 
         </div>
               
         <div className="naI">
               {secretaire.map((data)=>(data.auto_ecole))} 
             </div>      
         <div className="navbar">
         
            <Link to="#" className={sidebar?  "trois": "menu-bars"}>
                <FaIcons.FaBars onClick={showSidebar} />
            </Link>
         
            &nbsp;&nbsp;&nbsp;
              
         
         </div>
        
 <nav className={sidebar ? 'nav-menu active' : 'nav-menu'}>
    <ul className="nav-menu-items" >
        <li className="navbar-toggle">
            <Link to="#" className="menu-bars">
               <AiIcons.AiOutlineClose onClick={showSidebar}/>
            </Link>
        </li>
        <div className="carD">
                    <div className="profil">
        <AiIcons.AiOutlineUser className="icone-profil"   onClick={toggleMenu} />
        <div className="infos-utilisateur">
           <p className="inPo"   onClick={toggleMenu}> {secretaire.map((data)=>(data.poste))} </p>
          <p className="inN"  onClick={toggleMenu}> {secretaire.map((data)=>(data.nom))}  {secretaire.map((data)=>(data.prenom))} </p>
        </div>
      </div>


      {menuVisible && (
      <div className="dropdown-menu">
         <button className="option-btnMobi" onClick={e => setValid('on')}>Modifier</button>
         <button className="option-btnMobi2" onClick={logout}>Déconnexion</button>
      </div>
   )}

      </div>
        {sidedataDir.map((item, index) =>{
              const isActive = location.pathname.startsWith(item.path);
          return (
           
            <li key={index} className={isActive ? `${item.cName} active` : item.cName} onClick={showSidebar}>
                 <Link to={item.path+'/'+o}>
                    <span>{item.title}</span>
                 </Link>
                
            </li>
            
          
          )
 
        })}
       <li className="nav-text">
        <Link onClick={logout}  >
             
               <span>Deconnexion</span>
               
          </Link>
          </li> 
    </ul>
 </nav>
         </div>

         {supMod && (<div className='secretModalSup'>
            <div className='secretOverlay'></div>
            <div className='secretModalContent'>
                <div className="secretContainerModal"> 
                    <h3 className="secretFormTitle">MODIFIER MOT DE PASSE</h3>
                    
                    <div className='secretSupD'>
                        <input type="text" name="nom" className="secretInputCmot" id="nom" value={secretaire.map((data) => (data.nom))} readOnly />
                        <input type="text" name="prenom" className="secretInputCmot" id="prenom" value={secretaire.map((data) => (data.prenom))} readOnly />
                    </div>
                    
                    <label htmlFor="oldPassword">Ancien mot de passe</label><br/>
                    <div style={{ position: 'relative' }}>
                        <input type={showOldPassword ? 'text' : 'password'} name="oldPassword" className="secretInputCmot" id="oldPassword" onChange={e => setOld(e.target.value)} />
                        <span onClick={toggleOldPasswordVisibility} style={{ position: 'absolute', right: '10px', top: '50%', transform: 'translateY(-50%)', cursor: 'pointer' }}>
                            <i className={showOldPassword ? 'fas fa-eye-slash' : 'fas fa-eye'}></i>
                        </span>
                    </div>
                    <br/>
                    <h4 className="text-danger">{info1}</h4>
                    <br/>
                    <label htmlFor="newPassword">Nouveau mot de passe</label><br/>
                    <div style={{ position: 'relative' }}>
                        <input type={showNewPassword ? 'text' : 'password'} name="newPassword" className="secretInputCmot" id="newPassword" onChange={e => setNew1(e.target.value)} />
                        <span onClick={toggleNewPasswordVisibility} style={{ position: 'absolute', right: '10px', top: '50%', transform: 'translateY(-50%)', cursor: 'pointer' }}>
                            <i className={showNewPassword ? 'fas fa-eye-slash' : 'fas fa-eye'}></i>
                        </span>
                    </div>
                    <br/>
                    <label htmlFor="confirmPassword">Confirmer mot de passe</label><br/>
                    <div style={{ position: 'relative' }}>
                        <input type={showConfirmPassword ? 'text' : 'password'} name="confirmPassword" className="secretInputCmot" id="confirmPassword" onChange={e => setNew2(e.target.value)} />
                        <span onClick={toggleConfirmPasswordVisibility} style={{ position: 'absolute', right: '10px', top: '50%', transform: 'translateY(-50%)', cursor: 'pointer' }}>
                            <i className={showConfirmPassword ? 'fas fa-eye-slash' : 'fas fa-eye'}></i>
                        </span>
                    </div>
                    <br/>
                    <h4 className="text-danger">{info2}</h4>
                    <br/>
                    <div className="secretSupD">
                        <Link onClick={handleModf}><button className='secretNm1'>Modifier</button></Link>
                        <Link onClick={toggleSup}><button className='secretNm2'>Annuler</button></Link>
                    </div>
                </div>
            </div>
        </div>)}
{supMod2 && (<div className='modal'>
    <div className='overlay'>
        
   
    <div className='modal-content'>
       
      <h2>Mot de passe modifier avec succès</h2>
      <div className='supD'>
     
      <Link onClick={toggleSup2}> <button className='supC' >QUITER</button> </Link>
    
      </div>
     
      </div>
    </div>
   
</div>)} 


         </IconContext.Provider>
                 
         }


         if(loader){
            return(
                <div className="per">
                
                <div className="loader">
                <div className="overlayLo"> 
                    <div className="loaderP">
                    <Loader/>
                    </div>
                    </div>
                    </div>
                    </div>
            )
        }else{

    return(
        <body>

          

        <div className="" >
            


        
           
            {bar}
        
             
                
                
                <div className="disposition">
                 <Profil/>
                 
                 <Option/>
                 </div>
                     

                         </div>
                         <Footer/>
        </body>
    )
}
}
export default PageCandidatDir