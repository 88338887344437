
import React,{ useEffect, useState} from 'react';
import axios from 'axios';
import moment from "moment";
import croix from './croix.png'
import { Link, NavLink ,useParams,useNavigate,  useLocation} from "react-router-dom";
import moni from 'F:/projet/first/src/components/image/moni.png';
import log from 'F:/projet/first/src/components/image/log.png';
import Footer from '../components/footer';
import Cookies from 'js-cookie';
import { sidedataDir } from 'F:/projet/first/src/components/sidedataDir'
import * as FaIcons from "react-icons/fa";
import * as AiIcons from "react-icons/ai";
import { IconContext } from "react-icons";
import logo from 'F:/projet/first/src/components/image/logo4.jpg'
import Loader from "../components/loader";
export default function PayementDir(){
    const headers = {
        'Content-Type': 'application/json',
        'Access-Control-Allow-Origin': 'https://gestauto-d4cc8.web.app',
        'credentials': 'include',
      };
    const [ce,setCe]= useState(Cookies.get('autoa'))
    const [sidebar, setSidebar]= useState(false)
    const showSidebar = () => setSidebar(!sidebar)
    const [lu,setLu] = useState(Cookies.get('token'))
    const [tok, setTok]= useState('')
    const [error, setError] = useState(null);
    const [largeurEcran, setLargeurEcran] = useState(window.innerWidth);
    const mettreAJourLargeurEcran = () => {
        setLargeurEcran(window.innerWidth);
    
      };
      const [loader, setLoader]= useState(true)
      const [titre,setTitre]= useState(Cookies.get('titre'))
      const [gestauto,setGestauto]= useState(Cookies.get('gestauto'))
      const [pop, setPop]= useState('')
      const[inf, setInf] = useState(false)
      const [viewLoader, setViewLoader] = useState(false)
      const [select1,setSelect1]= useState(Cookies.get('select1'))
      const [select2,setSelect2]= useState(Cookies.get('select2'))
      const [old, setOld]= useState('')
    const [recup, setRecup]= useState('')
    const [new1, setNew1]= useState('')
    const [new2, setNew2]= useState('')
    const [info1, setInfo1]= useState('')
    const [info2, setInfo2]= useState('')
      const [view, setView]= useState('')
    const[dataLibe, setDataLibe]= useState('')
    const[dataDate, setDataDate]= useState('')
    const[dataNon, setDataNom]= useState('')
    const[dataPrenom, setDataPrenom]= useState('')
    const[dataMontant, setDataMontant]= useState('')
    const [listePay2, setListePay2]= useState([])
    
      if(tok!= ''){
        if(tok!= lu){
            Cookies.remove('token')
            Cookies.remove('autoa')
            Cookies.remove('titre')
            Cookies.remove('gestauto')
            window.location.reload();
            navigate('/')

        }
    }

    var navigate= useNavigate()
    useEffect(()=>{
         if(!Cookies.get('token')){
            navigate('/')
         }
    }, [])


    
    
    const logout = () =>{
        Cookies.remove('token')
        Cookies.remove('autoa')
        Cookies.remove('titre')
        Cookies.remove('gestauto')
        Cookies.remove('default')
        Cookies.remove('default2')
        Cookies.remove('defaultCode')
        Cookies.remove('defaultCode2')
        Cookies.remove('select1')
        Cookies.remove('select2')
        window.location.reload();
       navigate('/')
    }
    useEffect(()=>{
        if(!Cookies.get('token')){
           navigate('/')
        }
   }, [])





useEffect(() => {
        
    window.addEventListener('resize', mettreAJourLargeurEcran);

   
    return () => {
      window.removeEventListener('resize', mettreAJourLargeurEcran);
      
    };
   
  }, []);
// Recuperer token
useEffect(()=>{
    

axios.post('https://gestauto-56bfcbe4b876.herokuapp.com/setToken', { gestauto, titre}, { headers})
    .then(res => 
        {setTok(res.data[0].token)
        

        })
    .catch(err => console.log(err));
}, [])
//nav en haut
    const[profil, setProfil]= useState(false)
    const [loga, setLoga]= useState('')
    
    const [logaId, setLogaID]= useState(0)
    const [valeurLogaID, setValeurLogaID]= useState('')
    const showProfil = () => setProfil(!profil)

    const [secretaire, setSecretaire] = useState([])
    useEffect(()=>{
        axios.get('https://gestauto-56bfcbe4b876.herokuapp.com/navbar?id='+gestauto+'&titre='+titre+'', { headers})
        .then(res => setSecretaire(res.data))
        .catch(err => console.log(err));
    }, [])
    const [idAnnexe,setIdAnnexe]= useState('')
    const [lea,setLea]=useState([0])
    var date= new Date();
    const now= date.toLocaleDateString();
   const years= date.getFullYear()
  var  month=  date.getMonth()+1
  var autre= date.toISOString().split('T')[0]
    const[value,setValue]= useState('')
    const days= date.getDate()
    const day= date.getDate()
    var datee= new Date(years,month-2,days)
    var autre2= datee.toISOString().split('T')[0] 
    const [date1, setDate1]= useState('')
    const [date2, setDate2]= useState('')


    const [annexe, setAnnexe]= useState('')

    const [ville, setVille] = useState([])
    useEffect(()=>{
        axios.get('https://gestauto-56bfcbe4b876.herokuapp.com/candidatDir?id='+ce+'' , { headers})
        .then(res => {
            setVille(res.data)
            setLoga(res.data[0].ville)
            setLogaID(res.data[0].id_annexe)
            setValeurLogaID(res.data[0].id_ecole)
            setAnnexe(res.data[0].ville)
        })
        .catch(err => console.log(err));
    }, [])

    const [id_annexe]= ville.map((data)=>(data.id_annexe));
    
     const [query, setQuery]= useState(""); 
     var autre= date.toISOString().split('T')[0]
 


         useEffect(()=>{
            setDate1(autre2)
            setDate2(autre)
        }, [])
         
         const [payement, setPayement] = useState([])
         const [candidat, setCandidat]= useState([])



         function handleSubmit(event){
            event.preventDefault();
            setViewLoader(true)
            const [id_ecole]= ville.map((data)=>(data.id_ecole));

            
           
            axios.post('https://gestauto-56bfcbe4b876.herokuapp.com/candidatDir', {annexe,id_annexe,id_ecole}, { headers})
            .then(res => {
                
             
                const a= res.data[0].id_ecole
                axios.post('https://gestauto-56bfcbe4b876.herokuapp.com/ModalcandInfo2',{a}, { headers})
                .then(res => setCandidat(res.data))
                .catch(err => console.log(err));
                setLea(res.data)
if(value==''){
    axios.post('https://gestauto-56bfcbe4b876.herokuapp.com/infoPayement', {a,month,years}, { headers})
    .then(res => {
        setPayement(res.data)
       setViewLoader(false)
    
}).catch(err => {
    console.log(err)
 setError(err)   
    setViewLoader(false)
});

axios.post('https://gestauto-56bfcbe4b876.herokuapp.com/infoPayement2', {a,month,years}, { headers})
.then(res =>{ 
    
    setListePay2(res.data)    
    setLoader(false)         
}

).catch(err =>{
    console.log(err)
    setError(err)
    setLoader(false)
} );

}  else if(value=='Enregistré cette année'){
    axios.post('https://gestauto-56bfcbe4b876.herokuapp.com/payementInfoThisYearsInit',{years,a}, { headers})
    .then(res => {
        setPayement(res.data)
     setViewLoader(false)   
    })
    .catch(err => {
        console.log(err)
        setError(err)
        setViewLoader(false)
    });


    axios.post('https://gestauto-56bfcbe4b876.herokuapp.com/payementInfoThisYearsInit2',{years,a}, { headers})
    .then(res => {
        setListePay2(res.data)
     setViewLoader(false)   
    })
    .catch(err => {
        console.log(err)
        setError(err)
        setViewLoader(false)
    });

    if(o==0){
        axios.post('https://gestauto-56bfcbe4b876.herokuapp.com/defaPayementInfoThisYearsInit',{years,a}, { headers})
        .then(res => {
            setPayement(res.data)
         setViewLoader(false)   
        })
        .catch(err => {
            console.log(err)
         setError(err)
         setViewLoader(false)   
        });


        axios.post('https://gestauto-56bfcbe4b876.herokuapp.com/defaPayementInfoThisYearsInit2',{years,a}, { headers})
        .then(res => {
            setListePay2(res.data)
         setViewLoader(false)   
        })
        .catch(err => {
            console.log(err)
         setError(err)
         setViewLoader(false)   
        });

    }
}else if(value=='Tous les Paiements'){
    axios.post('https://gestauto-56bfcbe4b876.herokuapp.com/payInfoInit',{a}, { headers})
    .then(res => {
        setPayement(res.data)
     setViewLoader(false)   
    })
    .catch(err => {
        console.log(err)
     setError(err)
     setViewLoader(false)   
    });

    axios.post('https://gestauto-56bfcbe4b876.herokuapp.com/payInfoInit2',{a}, { headers})
    .then(res => {
        setListePay2(res.data)
     setViewLoader(false)   
    })
    .catch(err => {
        console.log(err)
     setError(err)
     setViewLoader(false)   
    });

    if(o==0){
        axios.post('https://gestauto-56bfcbe4b876.herokuapp.com/defaPayInfoInit',{le,a}, { headers})
        .then(res => {
            setPayement(res.data)
         setViewLoader(false)   
        })
        .catch(err => {
            console.log(err)
         setError(err)
         setViewLoader(false)   
        });

        axios.post('https://gestauto-56bfcbe4b876.herokuapp.com/defaPayInfoInit2',{le,a}, { headers})
        .then(res => {
            setListePay2(res.data)
         setViewLoader(false)   
        })
        .catch(err => {
            console.log(err)
         setError(err)
         setViewLoader(false)   
        });
    }
}
else if(value=='Enregistré ce mois'){
    axios.post('https://gestauto-56bfcbe4b876.herokuapp.com/payementInfoThisInit',{month,years,a}, { headers})
    .then(res => {
        setPayement(res.data)
     setViewLoader(false)   
    })
    .catch(err => {
        console.log(err)
     setError(err)
     setViewLoader(false)   
        
    });

    axios.post('https://gestauto-56bfcbe4b876.herokuapp.com/payementInfoThisInit2',{month,years,a}, { headers})
    .then(res => {
        setListePay2(res.data)
     setViewLoader(false)   
    })
    .catch(err => {
        console.log(err)
     setError(err)
     setViewLoader(false)   
        
    });

    if(o==0){
        axios.post('https://gestauto-56bfcbe4b876.herokuapp.com/defaPayementInfoThisInit',{month,years,a}, { headers})
    .then(res => {
        setPayement(res.data)
     setViewLoader(false)   
    })
    .catch(err => {
        console.log(err)
     setError(err)
     setViewLoader(false)   
    });


    axios.post('https://gestauto-56bfcbe4b876.herokuapp.com/defaPayementInfoThisInit2',{month,years,a}, { headers})
    .then(res => {
        setListePay2(res.data)
     setViewLoader(false)   
    })
    .catch(err => {
        console.log(err)
     setError(err)
     setViewLoader(false)   
    });

    }
    }

               
 
            }).catch(err => {
                console.log(err)
                setError(err)
                setViewLoader(false)
            });
         
        }


        const  le = lea.map((data)=>(data.id_ecole))
        var o=le
        const {id}= useParams();
        if (o!=0){
            o=le
        }else{
            
            o=id
        }

        if(logaId!='' && o==0){
            if(select1=== undefined || select2===undefined){
            axios.post('https://gestauto-56bfcbe4b876.herokuapp.com/defa', {loga,logaId}, { headers})
            .then(res => {
                
            
                const a= res.data[0].id_ecole
                
                axios.post('https://gestauto-56bfcbe4b876.herokuapp.com/ModalcandInfo2',{a}, { headers})
                .then(res => setCandidat(res.data))
                .catch(err => console.log(err));
                setLogaID('')
                /*axios.get('https://gestauto-56bfcbe4b876.herokuapp.com/get' )
                .then(res => setCandidat(res.data))*/       
                /*             
                axios.post('https://gestauto-56bfcbe4b876.herokuapp.com/infoPayement', {a,month,years}, { headers})
                .then(res =>{ 
                    
                    setPayement(res.data)    
                    setLoader(false)         
                }
        
                ).catch(err =>{
                    console.log(err)
                    setError(err)
                    setLoader(false)
                } );

                axios.post('https://gestauto-56bfcbe4b876.herokuapp.com/infoPayement2', {a,month,years}, { headers})
                .then(res =>{ 
                    
                    setListePay2(res.data)    
                    setLoader(false)         
                }
        
                ).catch(err =>{
                    console.log(err)
                    setError(err)
                    setLoader(false)
                } );
                 */




                axios.post('https://gestauto-56bfcbe4b876.herokuapp.com/PaieParDef', {date1,date2,a}, { headers})
                .then(res => {
                   setPayement(res.data)
                
                    setLoader(false)
                }).catch(err => {
                    console.log(err)
                 setError(err)
                 setLoader(false)   
                });
    
                axios.post('https://gestauto-56bfcbe4b876.herokuapp.com/PaieParDef2', {date1,date2,a}, { headers})
                .then(res => {
                   setListePay2(res.data)
                
                    setLoader(false)
                }).catch(err => {
                    console.log(err)
                 setError(err)
                 setLoader(false)   
                });






                  
            }).catch(err =>{
                console.log(err)
                setError(err)
                setLoader(false)
            } );
        }else if(select1 || select2){

            setLogaID('')
            console.log('nombre de repetition')
            axios.post('https://gestauto-56bfcbe4b876.herokuapp.com/defa', {loga,logaId}, { headers})
            .then(res => {
                
                setDate1(select1)
                    setDate2(select2) 
                const a= res.data[0].id_ecole

                axios.post('https://gestauto-56bfcbe4b876.herokuapp.com/vVoirPaye', {select1,select2,a}, { headers})
                .then(res => {
                   setPayement(res.data)
                
                    setLoader(false)
                }).catch(err => {
                    console.log(err)
                 setError(err)
                 setLoader(false)   
                });
    
                axios.post('https://gestauto-56bfcbe4b876.herokuapp.com/vVoirPaye2', {select1,select2,a}, { headers})
                .then(res => {
                   setListePay2(res.data)
                
                    setLoader(false)
                }).catch(err => {
                    console.log(err)
                 setError(err)
                 setLoader(false)   
                });


            }).catch(err =>{
                console.log(err)
                setError(err)
                setLoader(false)
            } );
        }
        }





        useEffect(()=>{
            if(o!=0){
            axios.post('https://gestauto-56bfcbe4b876.herokuapp.com/idAnnexe', {o}, { headers})
            .then(res =>{ 
                
                setIdAnnexe(res.data[0].ville)             
            }
               
    
            ).catch(err => console.log(err));
        }
        }, [])
            var b
           
       
            
           if(idAnnexe!=''){
            b= idAnnexe
          }  else{
            b= loga
          }



        useEffect(()=>{
            if(o!=0){
                if(select1=== undefined || select2===undefined){
                    /*
            axios.post('https://gestauto-56bfcbe4b876.herokuapp.com/ticPay', {o,month,years,le}, { headers})
            .then(res =>{ 
                
                setPayement(res.data)
                setLoader(false)           
            }
               

            ).catch(err => {
                console.log(err)
                setError(err)
                setLoader((false))
            }
            );

            axios.post('https://gestauto-56bfcbe4b876.herokuapp.com/ticPay2', {o,month,years,le}, { headers})
            .then(res =>{ 
                
                setListePay2(res.data)
                setLoader(false)           
            }
               

            ).catch(err => {
                console.log(err)
                setError(err)
                setLoader((false))
            }
            );

*/
let date1=autre2
let date2=autre
const a= o;





axios.post('https://gestauto-56bfcbe4b876.herokuapp.com/PaieParDef', {date1,date2,a}, { headers})
.then(res => {
   setPayement(res.data)

    setLoader(false)
}).catch(err => {
    console.log(err)
 setError(err)
 setLoader(false)   
});

axios.post('https://gestauto-56bfcbe4b876.herokuapp.com/PaieParDef2', {date1,date2,a}, { headers})
.then(res => {
   setListePay2(res.data)

    setLoader(false)
}).catch(err => {
    console.log(err)
 setError(err)
 setLoader(false)   
});






        }else if(select1 || select2){

            setDate1(select1)
            setDate2(select2) 
                const a= o


                axios.post('https://gestauto-56bfcbe4b876.herokuapp.com/vVoirPaye', {select1,select2,a}, { headers})
                .then(res => {
                   setPayement(res.data)
                
                    setLoader(false)
                }).catch(err => {
                    console.log(err)
                 setError(err)
                 setLoader(false)   
                });

                axios.post('https://gestauto-56bfcbe4b876.herokuapp.com/vVoirPaye2', {select1,select2,a}, { headers})
                .then(res => {
                   setListePay2(res.data)
                
                    setLoader(false)
                }).catch(err => {
                    console.log(err)
                 setError(err)
                 setLoader(false)   
                });

        }
        }
        }, [])


    
        function handlePropose(event){
            setViewLoader(true)
            Cookies.set('select1',date1)
            Cookies.set('select2',date2)
            event.preventDefault();
            if(o!=0){
            axios.post('https://gestauto-56bfcbe4b876.herokuapp.com/personPayementDir', {date1,date2,le,o}, { headers})
            .then(res => {
               setPayement(res.data)
            
                setViewLoader(false)
            }).catch(err => {
                console.log(err)
             setError(err)
             setViewLoader(false)   
            });
            axios.post('https://gestauto-56bfcbe4b876.herokuapp.com/personPayementDir2', {date1,date2,le,o}, { headers})
            .then(res => {
               setListePay2(res.data)
            
                setViewLoader(false)
            }).catch(err => {
                console.log(err)
             setError(err)
             setViewLoader(false)   
            });
        }
           else if(o==0){
                axios.post('https://gestauto-56bfcbe4b876.herokuapp.com/defaPersonPayementDir', {date1,date2,valeurLogaID}, { headers})
                .then(res => {
                   setPayement(res.data)
              setViewLoader(false)
                }).catch(err => {
                    console.log(err)
                    setViewLoader(false)
                    setError(err)
                });

                axios.post('https://gestauto-56bfcbe4b876.herokuapp.com/defaPersonPayementDir2', {date1,date2,valeurLogaID}, { headers})
                .then(res => {
                   setListePay2(res.data)
              setViewLoader(false)
                }).catch(err => {
                    console.log(err)
                    setViewLoader(false)
                    setError(err)
                });
            }
        }


        
        useEffect(()=>{
            axios.post('https://gestauto-56bfcbe4b876.herokuapp.com/ModalcandInfo',{o,le}, { headers})
            .then(res => setCandidat(res.data))
            .catch(err => console.log(err));
        }, [])

        const[modalCand, setModalCand]= useState(false)
        const toggle2 =()=> {
            setModalCand(!modalCand)
        }






//modification password



const[valid, setValid]= useState('')
              
const[valid2, setValid2]= useState('')
const[supMod, setSupMod]= useState(false) 

const toggleSup = ()=> {
  
 
  setSupMod(!supMod)
  setValid('')
 
}


if(valid!= ''){
 toggleSup()
 


} 



              

const[supMod2, setSupMod2]= useState(false) 

const toggleSup2 = ()=> {
  
 
  setSupMod2(!supMod2)
  setValid2('')
  
}


if(valid2!= ''){
 toggleSup2()
 


} 




function handleModf(event){
    const id= gestauto
    event.preventDefault();
    const currentPassword = secretaire.map((data) => data.password)[0];
    
    if(currentPassword===old){
    
        if(new1===new2){
            setInfo2('')
            axios.put('https://gestauto-56bfcbe4b876.herokuapp.com/upPassword/'+id, {new1,titre}, { headers})
            .then(res => {
                    
        axios.get('https://gestauto-56bfcbe4b876.herokuapp.com/navbar?id='+gestauto+'&titre='+titre+'', { headers })
        .then(res => setSecretaire(res.data))
        .catch(err => console.log(err));
                toggleSup()

            setValid2('on')
            }).catch(err => console.log(err));
        }else{
           
            setInfo2('Incorrecte')
            setTimeout(() => setInfo2(''), 3000)
        }
    }else{
        setInfo1('Incorrecte')
        setTimeout(() => setInfo1(''), 3000)
    }
}


const [menuVisible, setMenuVisible] = useState(false);

const toggleMenu = () => {
    setMenuVisible(!menuVisible);
  
};




const [showOldPassword, setShowOldPassword] = useState(false);
const [showNewPassword, setShowNewPassword] = useState(false);
const [showConfirmPassword, setShowConfirmPassword] = useState(false);


const toggleOldPasswordVisibility = () => setShowOldPassword(!showOldPassword);
const toggleNewPasswordVisibility = () => setShowNewPassword(!showNewPassword);
const toggleConfirmPasswordVisibility = () => setShowConfirmPassword(!showConfirmPassword);



useEffect(() => {
    const handleClickOutside = (event) => {
        if (menuVisible && !document.querySelector('.carD').contains(event.target)) {
            setMenuVisible(false);
        }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
        document.removeEventListener('mousedown', handleClickOutside);
    };
}, [menuVisible]);


                // ici bar
                var bar 
                const location = useLocation();
                if(largeurEcran>= 1000){
                bar=    <div className="barHaut">
 <div className="lep">
                    
                    
                    <img src={moni} className="user" onClick={showProfil} />
                    
                    {supMod && (<div className='secretModalSup'>
            <div className='secretOverlay'></div>
            <div className='secretModalContent'>
                <div className="secretContainerModal"> 
                    <h3 className="secretFormTitle">MODIFIER MOT DE PASSE</h3>
                    
                    <div className='secretSupD'>
                        <input type="text" name="nom" className="secretInputCmot" id="nom" value={secretaire.map((data) => (data.nom))} readOnly />
                        <input type="text" name="prenom" className="secretInputCmot" id="prenom" value={secretaire.map((data) => (data.prenom))} readOnly />
                    </div>
                    
                    <label htmlFor="oldPassword">Ancien mot de passe</label><br/>
                    <div style={{ position: 'relative' }}>
                        <input type={showOldPassword ? 'text' : 'password'} name="oldPassword" className="secretInputCmot" id="oldPassword" onChange={e => setOld(e.target.value)} />
                        <span onClick={toggleOldPasswordVisibility} style={{ position: 'absolute', right: '10px', top: '50%', transform: 'translateY(-50%)', cursor: 'pointer' }}>
                            <i className={showOldPassword ? 'fas fa-eye-slash' : 'fas fa-eye'}></i>
                        </span>
                    </div>
                    <br/>
                    <h4 className="text-danger">{info1}</h4>
                    <br/>
                    <label htmlFor="newPassword">Nouveau mot de passe</label><br/>
                    <div style={{ position: 'relative' }}>
                        <input type={showNewPassword ? 'text' : 'password'} name="newPassword" className="secretInputCmot" id="newPassword" onChange={e => setNew1(e.target.value)} />
                        <span onClick={toggleNewPasswordVisibility} style={{ position: 'absolute', right: '10px', top: '50%', transform: 'translateY(-50%)', cursor: 'pointer' }}>
                            <i className={showNewPassword ? 'fas fa-eye-slash' : 'fas fa-eye'}></i>
                        </span>
                    </div>
                    <br/>
                    <label htmlFor="confirmPassword">Confirmer mot de passe</label><br/>
                    <div style={{ position: 'relative' }}>
                        <input type={showConfirmPassword ? 'text' : 'password'} name="confirmPassword" className="secretInputCmot" id="confirmPassword" onChange={e => setNew2(e.target.value)} />
                        <span onClick={toggleConfirmPasswordVisibility} style={{ position: 'absolute', right: '10px', top: '50%', transform: 'translateY(-50%)', cursor: 'pointer' }}>
                            <i className={showConfirmPassword ? 'fas fa-eye-slash' : 'fas fa-eye'}></i>
                        </span>
                    </div>
                    <br/>
                    <h4 className="text-danger">{info2}</h4>
                    <br/>
                    <div className="secretSupD">
                        <Link onClick={handleModf}><button className='secretNm1'>Modifier</button></Link>
                        <Link onClick={toggleSup}><button className='secretNm2'>Annuler</button></Link>
                    </div>
                </div>
            </div>
        </div>)}
{supMod2 && (<div className='modal'>
    <div className='overlay'>
        
   
    <div className='modal-content'>
       
      <h2>Mot de passe modifier avec succès</h2>
      <div className='supD'>
     
      <Link onClick={toggleSup2}> <button className='supC' >QUITER</button> </Link>
    
      </div>
     
      </div>
    </div>
   
</div>)}
                    
                    <div className="hero"> 
                     <h3 className="nom">{secretaire.map((data)=>(data.nom))} {secretaire.map((data)=>(data.prenom))}</h3>
                     
                    </div>
       
                    <div class="options">
     <h2 className="form-title2"> {secretaire.map((data)=>(data.nom))} {secretaire.map((data)=>(data.prenom))}</h2>           
    <button class="option-btn" onClick={e => setValid('on')}>Modifier</button>
    <button class="option-btn2" onClick={logout}>Déconnexion</button>
  </div>
       
                    </div>

                    <NavLink to={'/accueil2/'+o} style={({isActive})=>{ return{background: isActive? "gray": 'rgb(6, 122, 116)',};} }><button className='pr'> Accueil</button> </NavLink>
            <NavLink to={'/candidatDir/'+o} style={({isActive})=>{ return{background: isActive? "gray": 'rgb(6, 122, 116)',};} }><button className='pr'> Candidats</button> </NavLink>
                <NavLink to={'/depenseDir/'+o} style={({isActive})=>{ return{background: isActive? "gray": 'rgb(6, 122, 116)',};} }><button className='pr'> Depenses</button> </NavLink>
                
                
                <NavLink to={'/payementDir/'+id} style={({isActive})=>{ return{background: isActive? "gray": 'rgb(6, 122, 116)',};} }><button className='pr'> Recettes</button> </NavLink>
                <NavLink to={'/bilan/'+o} style={({isActive})=>{ return{background: isActive? "gray": 'rgb(6, 122, 116)',};} }><button className='pr'> Bilan Global</button> </NavLink>
                <NavLink to={'/optPresenceDir/'+o} style={({isActive})=>{ return{background: isActive? "gray": 'rgb(6, 122, 116)',};} }><button className='pr'> Presence</button> </NavLink>
                <NavLink to={'/resultatDir/'+o} style={({isActive})=>{ return{background: isActive? "gray": 'rgb(6, 122, 116)',};} }><button className='pr'> Resultats</button> </NavLink>
                
                <Link onClick={logout} className="link">
<img src={log} className="am" />
<div className="deco">DECONNEXION</div>

</Link> 
                
                  
            
            </div>
            
                }else{
                    bar=  <IconContext.Provider value={{color:'#fff'}} >
                                 
                    <div className="icon">
                 <div>
                 &nbsp;&nbsp;&nbsp;
                 <img src={logo} className="userLog"  /> 
                 </div>
                 <div className="naI">
               {secretaire.map((data)=>(data.auto_ecole))} 
             </div>   
                       
                 <div className="navbar">
                 
                    <Link to="#" className={sidebar?  "trois": "menu-bars"}>
                        <FaIcons.FaBars onClick={showSidebar} />
                    </Link>
                 
                    &nbsp;&nbsp;&nbsp;
                      
                 
                 </div>
             
 <nav className={sidebar ? 'nav-menu active' : 'nav-menu'}>
    <ul className="nav-menu-items" >
        <li className="navbar-toggle">
            <Link to="#" className="menu-bars">
               <AiIcons.AiOutlineClose onClick={showSidebar}/>
            </Link>
        </li>
        <div className="carD">
                    <div className="profil">
        <AiIcons.AiOutlineUser className="icone-profil"   onClick={toggleMenu} />
        <div className="infos-utilisateur">
           <p className="inPo"   onClick={toggleMenu}> {secretaire.map((data)=>(data.poste))} </p>
          <p className="inN"  onClick={toggleMenu}> {secretaire.map((data)=>(data.nom))}  {secretaire.map((data)=>(data.prenom))} </p>
        </div>
      </div>


      {menuVisible && (
      <div className="dropdown-menu">
         <button className="option-btnMobi" onClick={e => setValid('on')}>Modifier</button>
         <button className="option-btnMobi2" onClick={logout}>Déconnexion</button>
      </div>
   )}

      </div>
        {sidedataDir.map((item, index) =>{
              const isActive = location.pathname.startsWith(item.path);
          return (
           
            <li key={index} className={isActive ? `${item.cName} active` : item.cName} onClick={showSidebar}>
               <Link to={item.path+'/'+o}>
                    <span>{item.title}</span>
                 </Link>
                
            </li>
            
          
          )
 
        })}
       <li className="nav-text">
        <Link onClick={logout}  >
             
               <span>Deconnexion</span>
               
          </Link>
          </li> 
    </ul>
 </nav>
                 </div>
                 {supMod && (<div className='secretModalSup'>
            <div className='secretOverlay'></div>
            <div className='secretModalContent'>
                <div className="secretContainerModal"> 
                    <h3 className="secretFormTitle">MODIFIER MOT DE PASSE</h3>
                    
                    <div className='secretSupD'>
                        <input type="text" name="nom" className="secretInputCmot" id="nom" value={secretaire.map((data) => (data.nom))} readOnly />
                        <input type="text" name="prenom" className="secretInputCmot" id="prenom" value={secretaire.map((data) => (data.prenom))} readOnly />
                    </div>
                    
                    <label htmlFor="oldPassword">Ancien mot de passe</label><br/>
                    <div style={{ position: 'relative' }}>
                        <input type={showOldPassword ? 'text' : 'password'} name="oldPassword" className="secretInputCmot" id="oldPassword" onChange={e => setOld(e.target.value)} />
                        <span onClick={toggleOldPasswordVisibility} style={{ position: 'absolute', right: '10px', top: '50%', transform: 'translateY(-50%)', cursor: 'pointer' }}>
                            <i className={showOldPassword ? 'fas fa-eye-slash' : 'fas fa-eye'}></i>
                        </span>
                    </div>
                    <br/>
                    <h4 className="text-danger">{info1}</h4>
                    <br/>
                    <label htmlFor="newPassword">Nouveau mot de passe</label><br/>
                    <div style={{ position: 'relative' }}>
                        <input type={showNewPassword ? 'text' : 'password'} name="newPassword" className="secretInputCmot" id="newPassword" onChange={e => setNew1(e.target.value)} />
                        <span onClick={toggleNewPasswordVisibility} style={{ position: 'absolute', right: '10px', top: '50%', transform: 'translateY(-50%)', cursor: 'pointer' }}>
                            <i className={showNewPassword ? 'fas fa-eye-slash' : 'fas fa-eye'}></i>
                        </span>
                    </div>
                    <br/>
                    <label htmlFor="confirmPassword">Confirmer mot de passe</label><br/>
                    <div style={{ position: 'relative' }}>
                        <input type={showConfirmPassword ? 'text' : 'password'} name="confirmPassword" className="secretInputCmot" id="confirmPassword" onChange={e => setNew2(e.target.value)} />
                        <span onClick={toggleConfirmPasswordVisibility} style={{ position: 'absolute', right: '10px', top: '50%', transform: 'translateY(-50%)', cursor: 'pointer' }}>
                            <i className={showConfirmPassword ? 'fas fa-eye-slash' : 'fas fa-eye'}></i>
                        </span>
                    </div>
                    <br/>
                    <h4 className="text-danger">{info2}</h4>
                    <br/>
                    <div className="secretSupD">
                        <Link onClick={handleModf}><button className='secretNm1'>Modifier</button></Link>
                        <Link onClick={toggleSup}><button className='secretNm2'>Annuler</button></Link>
                    </div>
                </div>
            </div>
        </div>)}
{supMod2 && (<div className='modal'>
    <div className='overlay'>
        
   
    <div className='modal-content'>
       
      <h2>Mot de passe modifier avec succès</h2>
      <div className='supD'>
     
      <Link onClick={toggleSup2}> <button className='supC' >QUITER</button> </Link>
    
      </div>
     
      </div>
    </div>
   
</div>)} 



                 </IconContext.Provider>
                         
                 }

                
                 let totalPay1= 0
                
                 payement.map((data, i)=> (
                   totalPay1=totalPay1+data.montant
               ))
               const togglePop =()=> {
                setInf(!inf)
            }
           
            if(pop!= ''){
                axios.post('https://gestauto-56bfcbe4b876.herokuapp.com/vivoPa', {pop},{ headers})
                .then(res => {
                  setDataLibe(res.data[0].libelle)
                  setDataDate(res.data[0].date)
                  setDataNom(res.data[0].nom)
                  setDataPrenom(res.data[0].prenom)
                  setDataMontant(res.data[0].montant)       
                }).catch(err => console.log(err));
                if(dataLibe !='' && dataDate !='' && dataMontant !=''){
                    console.log('les pop ', dataDate,dataMontant,dataLibe)          
                    togglePop()
                    setPop('')
                }      
            }
            
    
          
    
    
            const[modal, setModal]= useState(false) 
        
    const toggleModal = ()=> {
        setModal(!modal)
    }
    
    
        if(view!= ''){
            axios.post('https://gestauto-56bfcbe4b876.herokuapp.com/vevPaye', {years,month,day,view}, { headers})
            .then(res => {
               if(res.data.message== 'No match'){
                toggleModal()
                    setView('')
               }else{
                navigate('/updatePayementDir/'+res.data[0].id)
               }
                console.log(res);
                
                
            }).catch(err => console.log(err));
            setView('')
          }   
let totalPay2=0
          
          listePay2.map((data, i)=> (
            totalPay2=totalPay2+data.montant_payer
        ))
    
        const tot = totalPay1+totalPay2
    


          let load

          if(viewLoader==true){
              load=  <div className="avt">
              <div className="rin">
                <div className="chm">
                  <Loader/>
                </div>
              </div>
            </div>
          }else{
              load=''
          }


          if(loader){
            return(
                <div className="per">
                
                <div className="loader">
                <div className="overlayLo"> 
                    <div className="loaderP">
                    <Loader/>
                    </div>
                    </div>
                    </div>
                    </div>
            )
        }else if(error){
            return (<div>Une erreur est survenue : {error.message}</div>)
        }
        else{
             
               if(largeurEcran>= 510){
    return(
        <>
    <div className='wini'>
        


     {bar}
  


        <h1 className='win'>HISTORIQUE DES RECETTES</h1><br/><br/>
{load}
        <div className='inove'>
            <div>
                <div className='izi'>
        <div className="search">
             <input type="text" className='recherche' name="searchBar" id="searchBar" placeholder="Rechercher" onChange={e=>setQuery(e.target.value) }  ></input>
             </div></div><br/><br/><br/>
             <h4 className='che'> Recherche personnalisée </h4>
           <div className='person'>
           <input type='date' value={date1} className='oixe' onChange={e => setDate1(e.target.value)}></input> <input type='date' value={date2} className='oixe' onChange={e => setDate2(e.target.value)} ></input> 
           <button type='submit' onClick={handlePropose} className='butonSF6'>Appliquer</button>
           </div>
             </div>

             <div className='autre'  >       

        
             
             <select name="poste" className='autreSel' onChange={e => setAnnexe(e.target.value)} >
            <option >{b}</option>
                     
                {  
                    ville.filter((data) => data.ville!=b).map((data)=>(   
                        
                        <option value={data.ville}>{data.ville}</option>
                            
                    ))
                    
                }
           </select>  
           <select name="poste" className='autreSel'  onChange={e => setValue(e.target.value)} >
           <option value={'Enregistré ce mois'}>Enregistré ce mois</option>
                   <option value={'Tous les Paiements'}>Tous les Paiements</option>
            
            <option value={'Enregistré cette année'}>Enregistré cette année</option>
            
           
            
                </select> 
                <button className='butonSF4' onClick={handleSubmit}>Appliquer</button>
        </div>
           </div>
           <br/><br/><br/>
           <Link onClick={toggle2} className='yello'> <button className='ajouter'>AJOUTER UN PAIEMENT</button> </Link> <br/><br/>
          
<div className=''>
<div className=''>
<div className='uncard-list'>

{
                        
                        payement.filter((data) => data.date.toLowerCase().includes(query) || data.prenom.toLowerCase().includes(query) || data.nom.toLowerCase().includes(query)).reverse().map((data, i)=> (
                            <div className="bilan-depense">
                              
                                <span className='texte-depense'>
                                <div> {data.nom} {data.prenom}</div>
                            <span className="montant">{data.montant} FCFA</span><span className='txt'>
                            pour
                            </span>
                             <span className="libelle">{data.libelle}</span><span className='txt'> ce</span>  <span className="date">{moment(data.date).utc(1).format('dddd DD MMMM YYYY')}</span>.
                             </span>
                             <Link onClick={e => setView(data.id)}  >  <span className="icone-modifier1"> </span></Link> 
                               {modal && (<div className='modal'>
    <div className='overlay'>
        
    </div>
    <div className='modal-content'>
        <h2>Attention cette recette ne peut plus être modifiée!!!</h2>
      <Link onClick={toggleModal}>  <img src={croix} className='close-modal'/></Link>
    </div>
</div>)}
                             
                          </div>
                        ))
                      }

{
                        
                        listePay2.filter((data) => data.date.toLowerCase().includes(query) || data.prenom.toLowerCase().includes(query) || data.nom.toLowerCase().includes(query)).reverse().map((data, i)=> (
                            <div className="bilan-depense">
                              
                                <span className='texte-depense'>
                                <div> {data.nom} {data.prenom}</div>
                            <span className="montant">{data.montant_payer} FCFA</span><span className='txt'>
                            pour
                            </span>
                             <span className="libelle">Premier versement</span><span className='txt'> ce</span>  <span className="date">{moment(data.date).utc(1).format('dddd DD MMMM YYYY')}</span>.
                             </span>
                           
                          </div>
                        ))
                      }


</div>  
     <div className='vT'>
             TOTAL : {tot} FCFA
             </div>
</div>

</div>
<br/>
    </div>
    <Footer/>
    {modalCand && (<>
<div className="overlay"></div>
<div className="fenetre-modal">
     
<Link to={'/createCandidatDir/'+o} className='yello'> <button className='btnP'>NOUVEAU CANDIDAT</button> </Link><br/><br/>
                                 <input type="text" className="rechercheMod" name="searchBar" id="searchBar" placeholder="Rechercher" onChange={e=>setQuery(e.target.value)}></input>
                            
  <div className="modal-entete">
 
                                
                                </div>
                                <div className="modal-corps">
    <table className="table-inscrits">
      <thead>
        <tr>
          <th>Nom</th>
          <th>Prénom</th>
        </tr>
      </thead>
      <tbody>
        {   candidat.filter((data) => data.date.toLowerCase().includes(query) || data.nom.toLowerCase().includes(query) || data.prenom.toLowerCase().includes(query)).reverse().map((data, i)=> (
          <tr key={i} className={i % 2 === 0 ? "ligne-grise" : "ligne-blanche"}>
            <td ><Link to={'/pageCandidatPaye/'+ data.id} className='bnfs'>{data.nom}</Link></td>

            <td><Link to={'/pageCandidatPaye/'+ data.id} className='bnfs'>{data.prenom}</Link></td>
          </tr>
        ))}
      </tbody>
    </table>
  </div>
  <div className="boutons-modal">
    <Link onClick={toggle2}>
      <button className="bouton-annuler">QUITTER</button>
    </Link>
  </div>
  </div>
 

</>


                       
                    )}


    </>
    )
                    }
                    else if(largeurEcran>=510){
                        return(
                            <>
                        <div className='wini'>
                         {bar}
                            
                    {load}
                            <div className='barContener'>
                
                {/* Barre de recherche */}
            
                    <input type="text"className='seLe'  name="searchBar" id="searchBar" placeholder="Rechercher" onChange={e => setQuery(e.target.value)}></input>
               
                <br/>
<select name="poste"   className='rechBar' onChange={e => setAnnexe(e.target.value)} >
<option >{b}</option>
         
    {  
        ville.filter((data) => data.ville!=b).map((data)=>(   
            
            <option  value={data.ville}  >{data.ville}</option>
                
        ))
        
    }
</select> 

                
                {/* Barre de sélection avec bouton Appliquer */}
                <div className='barChoixT'>
                    <select name="poste" className='choosee' onChange={e => setValue(e.target.value)}>
                        <option>Ce mois</option>
                        <option>Cette année</option>
                        <option>Tous les paiements</option>
                    </select>
                    {/* Bouton Appliquer pour le select */}
                    <button className='butonSF' onClick={handleSubmit}>Appliquer</button>
                </div>
                
                {/* Barre de recherche personnalisée */}
                <div className='persBarT'>
                    
                    <input type='date' className='perSF' value={date1} onChange={e => setDate1(e.target.value)}></input>
                    <input type='date' className='perSF' value={date2} onChange={e => setDate2(e.target.value)}></input>
                    <button type='submit' className='butonSF' onClick={handlePropose}>APPLIQUER</button>
                </div>
                
                </div>
                     
                            <div className=''>
                            <div className=''>
                                <br/><br/>
                                 <Link onClick={toggle2} className='yello'> <button className='ajouter'>AJOUTER UN PAIEMENT</button> </Link><br/><br/>
                              
            
<div className='card-list'>

{
                        
                        payement.filter((data) => data.date.toLowerCase().includes(query) || data.prenom.toLowerCase().includes(query) || data.nom.toLowerCase().includes(query)).reverse().map((data, i)=> (
                            <div className="bilan-depense">
                              
                                <span className='texte-depense'>
                                <div> {data.nom} {data.prenom}</div>
                            <span className="montant">{data.montant} FCFA</span><span className='txt'>
                            pour
                            </span>
                             <span className="libelle">{data.libelle}</span><span className='txt'> ce</span>  <span className="date">{moment(data.date).utc(1).format('dddd DD MMMM YYYY')}</span>.
                             </span>
                             <Link onClick={e => setView(data.id)}  >  <span className="icone-modifier"> </span></Link> 
                               {modal && (<div className='modal'>
    <div className='overlay'>
        
    </div>
    <div className='modal-content'>
        <h2>Attention cette recette ne peut plus être modifiée!!!</h2>
      <Link onClick={toggleModal}>  <img src={croix} className='close-modal'/></Link>
    </div>
</div>)}
                             
                          </div>
                        ))
                      }

{
                        
                        listePay2.filter((data) => data.date.toLowerCase().includes(query) || data.prenom.toLowerCase().includes(query) || data.nom.toLowerCase().includes(query)).reverse().map((data, i)=> (
                            <div className="bilan-depense">
                              
                                <span className='texte-depense'>
                                <div> {data.nom} {data.prenom}</div>
                            <span className="montant">{data.montant_payer} FCFA</span><span className='txt'>
                            pour
                            </span>
                             <span className="libelle">Premier versement</span><span className='txt'> ce</span>  <span className="date">{moment(data.date).utc(1).format('dddd DD MMMM YYYY')}</span>.
                             </span>
                           
                          </div>
                        ))
                      }


</div>     
                                 <div className='vT'>
                                 TOTAL : {tot} FCFA
                                 </div>
                            </div>
                            
                        </div>
                    
                    
                       
                    
                        <br/>
                    
                    
                    
                        </div>
                    
                        <Footer/>
                        {modalCand && (<>
<div className="overlay"></div>
<div className="fenetre-modal">
     
<Link to={'/createCandidatDir/'+o} className='yello'> <button className='btnP'>NOUVEAU CANDIDAT</button> </Link><br/><br/>
                                 <input type="text" className="rechercheMod" name="searchBar" id="searchBar" placeholder="Rechercher" onChange={e=>setQuery(e.target.value)}></input>
                            
  <div className="modal-entete">
 
                                
                                </div>
                                <div className="modal-corps">
    <table className="table-inscrits">
      <thead>
        <tr>
          <th>Nom</th>
          <th>Prénom</th>
        </tr>
      </thead>
      <tbody>
        {   candidat.filter((data) => data.date.toLowerCase().includes(query) || data.nom.toLowerCase().includes(query) || data.prenom.toLowerCase().includes(query)).reverse().map((data, i)=> (
          <tr key={i} className={i % 2 === 0 ? "ligne-grise" : "ligne-blanche"}>
            <td ><Link to={'/pageCandidatPaye/'+ data.id} className='bnfs'>{data.nom}</Link></td>

            <td><Link to={'/pageCandidatPaye/'+ data.id} className='bnfs'>{data.prenom}</Link></td>
          </tr>
        ))}
      </tbody>
    </table>
  </div>
  <div className="boutons-modal">
    <Link onClick={toggle2}>
      <button className="bouton-annuler">QUITTER</button>
    </Link>
  </div>
  </div>
 

</>


                       
                    )}
                    
                    
                        </>
                        )
                    }
                    else{

                        return(
                            <>
                        <div className='wini'>
                         {bar}
                            
                    {load}
                            <div className='barContener'>
                
                {/* Barre de recherche */}
            
                    <input type="text"className='seLe'  name="searchBar" id="searchBar" placeholder="Rechercher" onChange={e => setQuery(e.target.value)}></input>
               
                <br/>
<select name="poste"   className='rechBar' onChange={e => setAnnexe(e.target.value)} >
<option >{b}</option>
         
    {  
        ville.filter((data) => data.ville!=b).map((data)=>(   
            
            <option  value={data.ville}  >{data.ville}</option>
                
        ))
        
    }
</select> 

                
                {/* Barre de sélection avec bouton Appliquer */}
                <div className='barChoixT'>
                    <select name="poste" className='choosee' onChange={e => setValue(e.target.value)}>
                        <option>Ce mois</option>
                        <option>Cette année</option>
                        <option>Tous les paiements</option>
                    </select>
                    {/* Bouton Appliquer pour le select */}
                    <button className='butonSF' onClick={handleSubmit}>Appliquer</button>
                </div>
                
                {/* Barre de recherche personnalisée */}
                <div className='persBarT'>
                    
                    <input type='date' className='perSF' value={date1} onChange={e => setDate1(e.target.value)}></input>
                    <input type='date' className='perSF' value={date2} onChange={e => setDate2(e.target.value)}></input>
                    <button type='submit' className='butonSF' onClick={handlePropose}>APPLIQUER</button>
                </div>
                
                </div>
                     
                            <div className=''>
                            <div className=''>
                                <br/><br/>
                                 <Link onClick={toggle2} className='yello'> <button className='ajouter'>AJOUTER UN PAIEMENT</button> </Link><br/><br/>
                              
            


{
                        
                        payement.filter((data) => data.date.toLowerCase().includes(query) || data.prenom.toLowerCase().includes(query) || data.nom.toLowerCase().includes(query)).reverse().map((data, i)=> (
                            <div className="bilan-depense">
                              
                                <span className='texte-depense'>
                                <div> {data.nom} {data.prenom}</div>
                            <span className="montant">{data.montant} FCFA</span><span className='txt'>
                            pour
                            </span>
                             <span className="libelle">{data.libelle}</span><span className='txt'> ce</span>  <span className="date">{moment(data.date).utc(1).format('dddd DD MMMM YYYY')}</span>.
                             </span>
                             <Link onClick={e => setView(data.id)}  >  <span className="icone-modifier"> </span></Link> 
                               {modal && (<div className='modal'>
    <div className='overlay'>
        
    </div>
    <div className='modal-content'>
        <h2>Attention cette recette ne peut plus être modifiée!!!</h2>
      <Link onClick={toggleModal}>  <img src={croix} className='close-modal'/></Link>
    </div>
</div>)}
                             
                          </div>
                        ))
                      }
                      {
                        
                        listePay2.filter((data) => data.date.toLowerCase().includes(query) || data.prenom.toLowerCase().includes(query) || data.nom.toLowerCase().includes(query)).reverse().map((data, i)=> (
                            <div className="bilan-depense">
                              
                                <span className='texte-depense'>
                                <div> {data.nom} {data.prenom}</div>
                            <span className="montant">{data.montant_payer} FCFA</span><span className='txt'>
                            pour
                            </span>
                             <span className="libelle">Premier versement</span><span className='txt'> ce</span>  <span className="date">{moment(data.date).utc(1).format('dddd DD MMMM YYYY')}</span>.
                             </span>
                           
                          </div>
                        ))
                      }


                
                                 <div className='vT'>
                                 TOTAL : {tot} FCFA
                                 </div>
                            </div>
                            
                        </div>
                    
                    
                       
                    
                        <br/>
                    
                    
                    
                        </div>
                    
                        <Footer/>
                        {modalCand && (<>
<div className="overlay"></div>
<div className="fenetre-modal">
     
<Link  to={'/createCandidatDir/'+o}  className='yello'> <button className='btnP'>NOUVEAU CANDIDAT</button> </Link><br/><br/>
                                 <input type="text" className="rechercheMod" name="searchBar" id="searchBar" placeholder="Rechercher" onChange={e=>setQuery(e.target.value)}></input>
                            
  <div className="modal-entete">
 
                                
                                </div>
                                <div className="modal-corps">
    <table className="table-inscrits">
      <thead>
        <tr>
          <th>Nom</th>
          <th>Prénom</th>
        </tr>
      </thead>
      <tbody>
        {   candidat.filter((data) => data.date.toLowerCase().includes(query) || data.nom.toLowerCase().includes(query) || data.prenom.toLowerCase().includes(query)).reverse().map((data, i)=> (
          <tr key={i} className={i % 2 === 0 ? "ligne-grise" : "ligne-blanche"}>
            <td ><Link to={'/pageCandidatPaye/'+ data.id} className='bnfs'>{data.nom}</Link></td>

            <td><Link to={'/pageCandidatPaye/'+ data.id} className='bnfs'>{data.prenom}</Link></td>
          </tr>
        ))}
      </tbody>
    </table>
  </div>
  <div className="boutons-modal">
    <Link onClick={toggle2}>
      <button className="bouton-annuler">QUITTER</button>
    </Link>
  </div>
  </div>
 

</>


                       
                    )}
                    
                    
                        </>
                        )
                    }
                }
}

