import React,{ useEffect, useState} from 'react';
import axios from 'axios';
import Loader from "../components/loader";
import moni from 'F:/projet/first/src/components/image/moni.png';
import log from 'F:/projet/first/src/components/image/log.png';
import logo from 'F:/projet/first/src/components/image/logo4.jpg'
import { Link ,  useLocation} from "react-router-dom";
import { useNavigate,useParams, NavLink } from "react-router-dom";
import Footer from '../components/footer';
import { sidedataDir } from 'F:/projet/first/src/components/sidedataDir'
import * as FaIcons from "react-icons/fa";
import * as AiIcons from "react-icons/ai";
import { IconContext } from "react-icons";
import secre from 'F:/projet/first/src/components/image/images.png'
import Cookies from 'js-cookie';
export default function CondDir(){
  
    const [candidat, setCandidat] = useState([])
    const [presence,setPresence]= useState([])
    const [id_cand, setId_cand]= useState([])
    const headers = {
        'Content-Type': 'application/json',
        'Access-Control-Allow-Origin': 'https://gestauto.seg.solutions',
        'credentials': 'include',
      };
    const [etat, setEtat]= useState('')
    const [know,setKnow]= useState('')
    const navigate = useNavigate();
    const {id}= useParams();
    const [titre,setTitre]= useState(Cookies.get('titre'))
    
    const [gestauto,setGestauto]= useState(Cookies.get('gestauto'))

    const [viewLoader, setViewLoader] = useState(false)
    const [error, setError] = useState(null);
    const [loader, setLoader]= useState(true)
    const [enabledSettings, setEnabledSettings] = useState([]);
    const [secretaire, setSecretaire] = useState([])

    const [old, setOld]= useState('')
    const [recup, setRecup]= useState('')
    const [new1, setNew1]= useState('')
    const [new2, setNew2]= useState('')
    const [info1, setInfo1]= useState('')
    const [info2, setInfo2]= useState('')

useEffect(()=>{
    axios.get('https://gestauto-56bfcbe4b876.herokuapp.com/navbar?id='+gestauto+'&titre='+titre+'', { headers})
    .then(res => setSecretaire(res.data))
    .catch(err => console.log(err));
}, [])

    const [sidebar, setSidebar]= useState(false)
const showSidebar = () => setSidebar(!sidebar)
const [lu,setLu] = useState(Cookies.get('token'))
const [tok, setTok]= useState('')


const [largeurEcran, setLargeurEcran] = useState(window.innerWidth);

const mettreAJourLargeurEcran = () => {
    setLargeurEcran(window.innerWidth);

  };
let o
  if (id){
    o=id
}else{
    
    o=0
}

  if(tok!= ''){
    if(tok!= lu){
        Cookies.remove('token')
        Cookies.remove('autoa')
        Cookies.remove('titre')
        Cookies.remove('gestauto')
        window.location.reload();
        navigate('/')

    }
}

useEffect(()=>{
     if(!Cookies.get('token')){
        navigate('/')
     }
}, [])


const[modal, setModal]= useState(false) 


const logout = () =>{
    Cookies.remove('token')
    Cookies.remove('autoa')
    Cookies.remove('titre')
    Cookies.remove('gestauto')
    Cookies.remove('default')
    Cookies.remove('default2')
    Cookies.remove('defaultCode')
    Cookies.remove('defaultCode2')
    window.location.reload();
   navigate('/')
}
useEffect(()=>{
    if(!Cookies.get('token')){
       navigate('/')
    }
}, [])





useEffect(() => {
    
window.addEventListener('resize', mettreAJourLargeurEcran);


return () => {
  window.removeEventListener('resize', mettreAJourLargeurEcran);
  
};

}, []);
// Recuperer token
useEffect(()=>{


axios.post('https://gestauto-56bfcbe4b876.herokuapp.com/setToken', { gestauto, titre},{ headers})
.then(res => 
    {setTok(res.data[0].token)
    

    })
.catch(err => {
                     console.log(err)
                    setError(err)
                    setViewLoader(false)
                    
                    });
}, [])
//nav en haut
const[profil, setProfil]= useState(false)

const showProfil = () => setProfil(!profil)





let id_ecole= id

    useEffect(()=>{
        axios.post('https://gestauto-56bfcbe4b876.herokuapp.com/condDir', {id_ecole}, { headers})
        .then(res =>{
     
             setCandidat(res.data)
             setLoader(false)
            })
        .catch(err =>{
             console.log(err)
             setError(err)
             setLoader(false)
        });
    }, [])
          
 

     const [query, setQuery]= useState(""); 
 
         let exp

         var date= new Date();
         const now= date.toLocaleDateString();
         
         
        var years= date.getFullYear();
         
       var  month= date.getMonth()+1;
        
        var day= date.getDate();
        var autre= date.toISOString().split('T')[0]  
        const handleCheckboxChange = (candidateId) => {
            setEnabledSettings((prevEnabledSettings) => {
              if (prevEnabledSettings.includes(candidateId)) {
                // Si le candidat est déjà dans enabledSettings, le retire
                return prevEnabledSettings.filter((id) => id !== candidateId);
              } else {
                // Sinon, l'ajoute
                return [...prevEnabledSettings, candidateId];
              }
            });
          };

       
            function handleSubmit(event){
              setViewLoader(true)
                event.preventDefault();
            
                var heureCourante = date.getHours() + ':' + date.getMinutes() + ':' + date.getSeconds();
                axios.post('https://gestauto-56bfcbe4b876.herokuapp.com/exp', {enabledSettings,now,years,month,day,id_ecole,autre,heureCourante}, { headers})
                .then(res => {
                  window.location.reload();
                 
               setViewLoader(false)

                    
                    
                }).catch(err => {
                     console.log(err)
                    setError(err)
                    setViewLoader(false)
                    
                    }); 
               
                
            }
            var tab=[candidat.lenght]
            
            useEffect(()=>{
                axios.post('https://gestauto-56bfcbe4b876.herokuapp.com/dateDir', {years,month,day,id_ecole}, { headers})
                .then(res => {
                   setId_cand(res.data)
                
                    setKnow(res.data.message);
                    setLoader(false)
                }).catch(err => {
                     console.log(err)
                    setError(err)
                    setViewLoader(false)
                    
                    });
            }, [])
            
      




//modification password



const[valid, setValid]= useState('')
              
const[valid2, setValid2]= useState('')
const[supMod, setSupMod]= useState(false) 

const toggleSup = ()=> {
  
 
  setSupMod(!supMod)
  setValid('')
 
}


if(valid!= ''){
 toggleSup()
 


} 



              

const[supMod2, setSupMod2]= useState(false) 

const toggleSup2 = ()=> {
  
 
  setSupMod2(!supMod2)
  setValid2('')
  
}


if(valid2!= ''){
 toggleSup2()
 


} 




function handleModf(event){
    const id= gestauto
    event.preventDefault();
    const currentPassword = secretaire.map((data) => data.password)[0];
    
    if(currentPassword===old){
    
        if(new1===new2){
            setInfo2('')
            axios.put('https://gestauto-56bfcbe4b876.herokuapp.com/upPassword/'+id, {new1,titre}, { headers})
            .then(res => {
                  
        axios.get('https://gestauto-56bfcbe4b876.herokuapp.com/navbar?id='+gestauto+'&titre='+titre+'', { headers })
        .then(res => setSecretaire(res.data))
        .catch(err => console.log(err));
                toggleSup()

            setValid2('on')
            }).catch(err => console.log(err));
        }else{
           
            setInfo2('Incorrecte')
            setTimeout(() => setInfo2(''), 3000)
        }
    }else{
        setInfo1('Incorrecte')
        setTimeout(() => setInfo1(''), 3000)
    }
}


const [menuVisible, setMenuVisible] = useState(false);

const toggleMenu = () => {
    setMenuVisible(!menuVisible);
  
};



const [showOldPassword, setShowOldPassword] = useState(false);
const [showNewPassword, setShowNewPassword] = useState(false);
const [showConfirmPassword, setShowConfirmPassword] = useState(false);


const toggleOldPasswordVisibility = () => setShowOldPassword(!showOldPassword);
const toggleNewPasswordVisibility = () => setShowNewPassword(!showNewPassword);
const toggleConfirmPasswordVisibility = () => setShowConfirmPassword(!showConfirmPassword);




useEffect(() => {
    const handleClickOutside = (event) => {
        if (menuVisible && !document.querySelector('.carD').contains(event.target)) {
            setMenuVisible(false);
        }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
        document.removeEventListener('mousedown', handleClickOutside);
    };
}, [menuVisible]);


// ici bar
var bar 
const location = useLocation();
if(largeurEcran>= 1000){
    bar=<div className="barHaut">
<div className="lep">
        
        
        <img src={moni} className="user" onClick={showProfil} />
        
        {supMod && (<div className='secretModalSup'>
            <div className='secretOverlay'></div>
            <div className='secretModalContent'>
                <div className="secretContainerModal"> 
                    <h3 className="secretFormTitle">MODIFIER MOT DE PASSE</h3>
                    
                    <div className='secretSupD'>
                        <input type="text" name="nom" className="secretInputCmot" id="nom" value={secretaire.map((data) => (data.nom))} readOnly />
                        <input type="text" name="prenom" className="secretInputCmot" id="prenom" value={secretaire.map((data) => (data.prenom))} readOnly />
                    </div>
                    
                    <label htmlFor="oldPassword">Ancien mot de passe</label><br/>
                    <div style={{ position: 'relative' }}>
                        <input type={showOldPassword ? 'text' : 'password'} name="oldPassword" className="secretInputCmot" id="oldPassword" onChange={e => setOld(e.target.value)} />
                        <span onClick={toggleOldPasswordVisibility} style={{ position: 'absolute', right: '10px', top: '50%', transform: 'translateY(-50%)', cursor: 'pointer' }}>
                            <i className={showOldPassword ? 'fas fa-eye-slash' : 'fas fa-eye'}></i>
                        </span>
                    </div>
                    <br/>
                    <h4 className="text-danger">{info1}</h4>
                    <br/>
                    <label htmlFor="newPassword">Nouveau mot de passe</label><br/>
                    <div style={{ position: 'relative' }}>
                        <input type={showNewPassword ? 'text' : 'password'} name="newPassword" className="secretInputCmot" id="newPassword" onChange={e => setNew1(e.target.value)} />
                        <span onClick={toggleNewPasswordVisibility} style={{ position: 'absolute', right: '10px', top: '50%', transform: 'translateY(-50%)', cursor: 'pointer' }}>
                            <i className={showNewPassword ? 'fas fa-eye-slash' : 'fas fa-eye'}></i>
                        </span>
                    </div>
                    <br/>
                    <label htmlFor="confirmPassword">Confirmer mot de passe</label><br/>
                    <div style={{ position: 'relative' }}>
                        <input type={showConfirmPassword ? 'text' : 'password'} name="confirmPassword" className="secretInputCmot" id="confirmPassword" onChange={e => setNew2(e.target.value)} />
                        <span onClick={toggleConfirmPasswordVisibility} style={{ position: 'absolute', right: '10px', top: '50%', transform: 'translateY(-50%)', cursor: 'pointer' }}>
                            <i className={showConfirmPassword ? 'fas fa-eye-slash' : 'fas fa-eye'}></i>
                        </span>
                    </div>
                    <br/>
                    <h4 className="text-danger">{info2}</h4>
                    <br/>
                    <div className="secretSupD">
                        <Link onClick={handleModf}><button className='secretNm1'>Modifier</button></Link>
                        <Link onClick={toggleSup}><button className='secretNm2'>Annuler</button></Link>
                    </div>
                </div>
            </div>
        </div>)}
{supMod2 && (<div className='modal'>
    <div className='overlay'>
        
   
    <div className='modal-content'>
       
      <h2>Mot de passe modifier avec succès</h2>
      <div className='supD'>
     
      <Link onClick={toggleSup2}> <button className='supC' >QUITER</button> </Link>
    
      </div>
     
      </div>
    </div>
   
</div>)}
                    
                    <div className="hero"> 
                     <h3 className="nom">{secretaire.map((data)=>(data.nom))} {secretaire.map((data)=>(data.prenom))}</h3>
                     
                    </div>
       
                    <div class="options">
     <h2 className="form-title2"> {secretaire.map((data)=>(data.nom))} {secretaire.map((data)=>(data.prenom))}</h2>           
    <button class="option-btn" onClick={e => setValid('on')}>Modifier</button>
    <button class="option-btn2" onClick={logout}>Déconnexion</button>
  </div>

        </div>

    <NavLink to={'/accueil2/'+o} style={({isActive})=>{ return{background: isActive? "gray": 'rgb(6, 122, 116)',};} }><button className='pr'> Accueil</button> </NavLink>
<NavLink to={'/candidatDir/'+o} style={({isActive})=>{ return{background: isActive? "gray": 'rgb(6, 122, 116)',};} }><button className='pr'> Candidats</button> </NavLink>
<NavLink to={'/depenseDir/'+o} style={({isActive})=>{ return{background: isActive? "gray": 'rgb(6, 122, 116)',};} }><button className='pr'> Depenses</button> </NavLink>


<NavLink to={'/payementDir/'+o} style={({isActive})=>{ return{background: isActive? "gray": 'rgb(6, 122, 116)',};} }><button className='pr'> Recettes</button> </NavLink>
<NavLink to={'/bilan/'+o} style={({isActive})=>{ return{background: isActive? "gray": 'rgb(6, 122, 116)',};} }><button className='pr'> Bilan Global</button> </NavLink>
<NavLink to={'/optPresenceDir/'+o} className='dirResult'><button className='pr'> Presence</button> </NavLink>
<NavLink to={'/resultatDir/'+o} style={({isActive})=>{ return{background: isActive? "gray": 'rgb(6, 122, 116)',};} }><button className='pr'> Resultats</button> </NavLink>

<Link onClick={logout} className="link">
<img src={log} className="am" />
<div className="deco">DECONNEXION</div>

</Link> 

  

</div>


}else{
    bar=  <IconContext.Provider value={{color:'#fff'}} >
                 
    <div className="icon">
 <div>
 &nbsp;&nbsp;&nbsp;
 <img src={logo} className="userLog"  /> 
 </div>
       
 <div className="naI">
               {secretaire.map((data)=>(data.auto_ecole))} 
             </div>   
 <div className="navbar">
 
    <Link to="#" className={sidebar?  "trois": "menu-bars"}>
        <FaIcons.FaBars onClick={showSidebar} />
    </Link>
 
    &nbsp;&nbsp;&nbsp;
      
 
 </div>
 
 <nav className={sidebar ? 'nav-menu active' : 'nav-menu'}>
    <ul className="nav-menu-items" >
        <li className="navbar-toggle">
            <Link to="#" className="menu-bars">
               <AiIcons.AiOutlineClose onClick={showSidebar}/>
            </Link>
        </li>
        <div className="carD">
                    <div className="profil">
        <AiIcons.AiOutlineUser className="icone-profil"   onClick={toggleMenu} />
        <div className="infos-utilisateur">
           <p className="inPo"   onClick={toggleMenu}> {secretaire.map((data)=>(data.poste))} </p>
          <p className="inN"  onClick={toggleMenu}> {secretaire.map((data)=>(data.nom))}  {secretaire.map((data)=>(data.prenom))} </p>
        </div>
      </div>


      {menuVisible && (
      <div className="dropdown-menu">
         <button className="option-btnMobi" onClick={e => setValid('on')}>Modifier</button>
         <button className="option-btnMobi2" onClick={logout}>Déconnexion</button>
      </div>
   )}

      </div>
        {sidedataDir.map((item, index) =>{
            const isActive = location.pathname.startsWith(item.path);
          return (
           
            <li key={index} className={isActive ? `${item.cName} active` : item.cName} onClick={showSidebar}>
                   <Link to={item.path+'/'+o}>
                    <span>{item.title}</span>
                 </Link>
                
            </li>
            
          
          )
 
        })}
       <li className="nav-text">
        <Link onClick={logout}  >
             
               <span>Deconnexion</span>
               
          </Link>
          </li> 
    </ul>
 </nav>
 </div>

 {supMod && (<div className='secretModalSup'>
            <div className='secretOverlay'></div>
            <div className='secretModalContent'>
                <div className="secretContainerModal"> 
                    <h3 className="secretFormTitle">MODIFIER MOT DE PASSE</h3>
                    
                    <div className='secretSupD'>
                        <input type="text" name="nom" className="secretInputCmot" id="nom" value={secretaire.map((data) => (data.nom))} readOnly />
                        <input type="text" name="prenom" className="secretInputCmot" id="prenom" value={secretaire.map((data) => (data.prenom))} readOnly />
                    </div>
                    
                    <label htmlFor="oldPassword">Ancien mot de passe</label><br/>
                    <div style={{ position: 'relative' }}>
                        <input type={showOldPassword ? 'text' : 'password'} name="oldPassword" className="secretInputCmot" id="oldPassword" onChange={e => setOld(e.target.value)} />
                        <span onClick={toggleOldPasswordVisibility} style={{ position: 'absolute', right: '10px', top: '50%', transform: 'translateY(-50%)', cursor: 'pointer' }}>
                            <i className={showOldPassword ? 'fas fa-eye-slash' : 'fas fa-eye'}></i>
                        </span>
                    </div>
                    <br/>
                    <h4 className="text-danger">{info1}</h4>
                    <br/>
                    <label htmlFor="newPassword">Nouveau mot de passe</label><br/>
                    <div style={{ position: 'relative' }}>
                        <input type={showNewPassword ? 'text' : 'password'} name="newPassword" className="secretInputCmot" id="newPassword" onChange={e => setNew1(e.target.value)} />
                        <span onClick={toggleNewPasswordVisibility} style={{ position: 'absolute', right: '10px', top: '50%', transform: 'translateY(-50%)', cursor: 'pointer' }}>
                            <i className={showNewPassword ? 'fas fa-eye-slash' : 'fas fa-eye'}></i>
                        </span>
                    </div>
                    <br/>
                    <label htmlFor="confirmPassword">Confirmer mot de passe</label><br/>
                    <div style={{ position: 'relative' }}>
                        <input type={showConfirmPassword ? 'text' : 'password'} name="confirmPassword" className="secretInputCmot" id="confirmPassword" onChange={e => setNew2(e.target.value)} />
                        <span onClick={toggleConfirmPasswordVisibility} style={{ position: 'absolute', right: '10px', top: '50%', transform: 'translateY(-50%)', cursor: 'pointer' }}>
                            <i className={showConfirmPassword ? 'fas fa-eye-slash' : 'fas fa-eye'}></i>
                        </span>
                    </div>
                    <br/>
                    <h4 className="text-danger">{info2}</h4>
                    <br/>
                    <div className="secretSupD">
                        <Link onClick={handleModf}><button className='secretNm1'>Modifier</button></Link>
                        <Link onClick={toggleSup}><button className='secretNm2'>Annuler</button></Link>
                    </div>
                </div>
            </div>
        </div>)}
{supMod2 && (<div className='modal'>
    <div className='overlay'>
        
   
    <div className='modal-content'>
       
      <h2>Mot de passe modifier avec succès</h2>
      <div className='supD'>
     
      <Link onClick={toggleSup2}> <button className='supC' >QUITER</button> </Link>
    
      </div>
     
      </div>
    </div>
   
</div>)} 


 </IconContext.Provider>
         
 }


 let load

 if(viewLoader==true){
     load=   <div className="avt">
     <div className="rin">
       <div className="chm">
         <Loader/>
       </div>
     </div>
   </div>
 }else{
     load=''
 }



 if(loader){
    return(
        <div className="per">
        
        <div className="loader">
        <div className="overlayLo"> 
            <div className="loaderP">
            <Loader/>
            </div>
            </div>
            </div>
            </div>
    )
}else if(error){
    return (<div>Une erreur est survenue : {error.message}</div>)
}
else{
    {/* */}

    if(largeurEcran>= 510){  
           if(know=='oui'){
            return(
                <>
                <div className='wini'>
                {bar}
                <br/> <br/><br/><br/><br/><br/><br/><br/>
                <div className='van'>
                <h2 className='af'>La liste de presence du {now} est enregistré !!! </h2>
                
                <Link to={'/voirCondDir/'+id} > <button className='ess'>VOIR PRESENCE</button></Link>
                </div>
                       </div>
                       <Footer/>
                </>
              )
           }   else if(know=='non'){
            return(
                <>
                
                <div className='wini'>
                {bar}
                <h1 className='win'>Liste de présence du {now}</h1><br/><br/>
                
                <div className="search">
                     <input type="text" className="recherche" name="searchBar" id="searchBar" placeholder="Rechercher" onChange={e=>setQuery(e.target.value)}></input>
                </div>
                <div className="searche_result">
                    
                   
                </div>
                <br/><br/>
               {load}
            <div className=''>
                <div className=''>
                    
                <div className="card-list"  >
                                 {
                                            candidat.filter((data) => data.nom.toLowerCase().includes(query) || data.prenom.toLowerCase().includes(query)).map((data, i)=> (
                                                <div className="card" onClick={() => handleCheckboxChange(data.id)}>
                                                          <div className="image-container8">
                 <img src={data.image ? `${data.image}` : (data.sexe === 'Masculin' ? moni : secre)} alt="Candidat" className="profile-image" />

         
          </div>
                                         
                                         
                                            <div className='info'>
                                                <p className='name'> {data.nom} {data.prenom}</p>
                                             
                                                <p className='contacts'> {data.contact}</p>
                                               
                                                <input type='checkbox' value={data.id} className='checko' name='settings' checked={enabledSettings.includes(data.id)}
    ></input>
                
                                                </div>
                                            </div>
                                        
                                            ))
                                          } 
                                 </div>
                </div>
                <h3 className='pesen'>{etat}</h3>
              <button type="submit" className='enregistrercond' onClick={handleSubmit} >ENREGISTRER</button> 
        
            </div>
            
            </div>
            <Footer/>
            </>
            )
           }  else{
            <>
            <div className='wini'>
            {bar}
            <br/> <br/><br/><br/><br/><br/><br/><br/>
            
                   </div>
                   <Footer/>
            </>
           }  

   
        }else{
            if(know=='oui'){
                return(
                    <>
                    <div className='wini'>
                    {bar}
                    <br/> <br/><br/><br/><br/><br/><br/><br/>
                    <div className='van'>
                    <h2 className='af'>La liste de presence du {now} est enregistré !!! </h2>
                    
                    <Link to={'/voirCondDir/'+id} > <button className='ess'>VOIR PRESENCE</button></Link>
                    </div>
                           </div>
                           <Footer/>
                    </>
                  )
               }   else if(know=='non'){
                return(
                    <>
                    
                    <div className='wini'>
                    {bar}
                    <h1 className='win'>Liste de présence du {now}</h1><br/><br/>
                    {load}
                    <div className="search">
                         <input type="text" className="recherche" name="searchBar" id="searchBar" placeholder="Rechercher" onChange={e=>setQuery(e.target.value)}></input>
                    </div>
                    <div className="searche_result">
                        
                       
                    </div>
                    <br/><br/>
                   
                <div className=''>
                    <div className=''>
                        
                    <div className="card-list"  >
                                 {
                                            candidat.filter((data) => data.nom.toLowerCase().includes(query) || data.prenom.toLowerCase().includes(query)).map((data, i)=> (
                                                <div className="card" onClick={() => handleCheckboxChange(data.id)}>
                                                             <div className="image-container8">
                 <img src={data.image ? `${data.image}` : (data.sexe === 'Masculin' ? moni : secre)} alt="Candidat" className="profile-image" />

         
          </div>
                                          
                                          
                                             <div className='info'>
                                                <p className='name'> {data.nom} {data.prenom}</p>
                                             
                                                <p className='contacts'> {data.contact}</p>
                                               
                                                <input type='checkbox' value={data.id} className='checko' name='settings' checked={enabledSettings.includes(data.id)}
    ></input>
                
                                                </div>
                                            </div>
                                        
                                            ))
                                          } 
                                 </div>
                    </div>
                    <h3 className='pesen'>{etat}</h3>
                  <button type="submit" className='enregistrercond' onClick={handleSubmit} >ENREGISTRER</button> 
            
                </div>
                
                </div>
                <Footer/>
                </>
                )
               }  else{
                <>
                <div className='wini'>
                {bar}
                <br/> <br/><br/><br/><br/><br/><br/><br/>
                
                       </div>
                       <Footer/>
                </>
               }  
        }
    }
            
        }
    

        
    
       