import React,{ useEffect, useState} from 'react';
import axios from 'axios';
import Navbar from 'F:/projet/first/src/components/navbar';
import Footer from '../components/footer';
import { Link } from "react-router-dom";
import Cookies from 'js-cookie';
import Loader from "../components/loader";
import moni from 'F:/projet/first/src/components/image/moni.png';
import secre from 'F:/projet/first/src/components/image/images.png'
import { FaPhoneAlt } from 'react-icons/fa';
export default function PageCandidatMoni(){
    var date= new Date();
    const now= date.toLocaleDateString();
   const years= date.getFullYear()
  var  month=  date.getMonth()+1
  var autre= date.toISOString().split('T')[0]
  const days= date.getDate()
  var datee= new Date(years,month-2,days)

  const [select1,setSelect1]= useState(Cookies.get('select1'))
  const [select2,setSelect2]= useState(Cookies.get('select2'))


  var autre2= datee.toISOString().split('T')[0] 
    const[value,setValue]= useState('')
    const [date1, setDate1]= useState('')
    const [date2, setDate2]= useState('')
    const [candidat, setCandidat] = useState([])
    const [largeurEcran, setLargeurEcran] = useState(window.innerWidth);
  var dateActuelle = new Date();
  const [viewLoader, setViewLoader] = useState(false)
  const [error, setError] = useState(null);
  const [loader, setLoader]= useState(true)
    const [gestauto,setGestauto]= useState(Cookies.get('gestauto'))
    const [mie, setMie]= useState(Cookies.get('mie'))
    const headers = {
        'Content-Type': 'application/json',
        'Access-Control-Allow-Origin': 'https://gestauto.seg.solutions',
        'credentials': 'include',
      };






    useEffect(()=>{
        if(select1=== undefined || select2===undefined){
        axios.post('https://gestauto-56bfcbe4b876.herokuapp.com/candidatThisMoni',{month,years,mie}, { headers})
        .then(res =>{
             setCandidat(res.data)
             setLoader(false)
        })
        .catch(err =>{
             console.log(err)
             setError(err)
             setLoader(false)
            });
        }else if(select1 || select2){
            axios.post('https://gestauto-56bfcbe4b876.herokuapp.com/secreVvoir', {select1,select2,gestauto,mie}, { headers})
            .then(res => {
                setDate1(select1)
                setDate2(select2)
               setCandidat(res.data)
               
               setLoader(false)
                
            }).catch(err => {
                console.log(err)
                setError(err)
                setLoader(false)
               
            });
        }
    }, [])

     

     const [query, setQuery]= useState(""); 
   
         
         function handleTout(event){
            setViewLoader(true)
            event.preventDefault();
            if(value=='Enregistré ce mois'){
                axios.post('https://gestauto-56bfcbe4b876.herokuapp.com/candidatThisMoni',{month,years,mie}, { headers})
                .then(res =>{
                     setCandidat(res.data)
                    setViewLoader(false)
                    })
                .catch(err =>{
                     console.log(err)
                     setError(err)
                     setViewLoader(false)
                });
                }
            else if(value=='Enregistré cette année'){
                axios.post('https://gestauto-56bfcbe4b876.herokuapp.com/candidatThisYears',{years,mie}, { headers})
                .then(res =>{
                     setCandidat(res.data)
                     setViewLoader(false)
                    })
                .catch(err =>{
                     console.log(err)
                     setError(err)
                     setViewLoader(false)
                    });
            }else if(value=='Tous les Candidats'){
                axios.get('https://gestauto-56bfcbe4b876.herokuapp.com/candidatMoni?id='+mie+'', { headers})
                .then(res =>{
                     setCandidat(res.data)
                     setViewLoader(false)
                    })
                .catch(err =>{
                     console.log(err)
                     setError(err)
                     setViewLoader(false)
                    });
            }else if(value=='Qui ont retiré leurs permis'){
                axios.post('https://gestauto-56bfcbe4b876.herokuapp.com/candidatPermis',{mie}, { headers})
                .then(res =>{
                     setCandidat(res.data)
                     setViewLoader(false)
                })
                .catch(err =>{
                     console.log(err)
                     setError(err)
                     setViewLoader(false)
                });
            }
           
        }
        useEffect(()=>{
            setDate1(autre2)
            setDate2(autre)
        }, [])


        const mettreAJourLargeurEcran = () => {
            setLargeurEcran(window.innerWidth);
        
          };
        
           
           
            
            
            useEffect(() => {      
                window.addEventListener('resize', mettreAJourLargeurEcran);      
                return () => {
                  window.removeEventListener('resize', mettreAJourLargeurEcran);        
                };      
              }, []);

        
    
        function handlePropose(event){
            setViewLoader(true)
            Cookies.set('select1',date1)
            Cookies.set('select2',date2)
            event.preventDefault();
            axios.post('https://gestauto-56bfcbe4b876.herokuapp.com/personCandidat', {date1,date2,gestauto,mie}, { headers})
            .then(res => {
               setCandidat(res.data)
               setViewLoader(false)
                
                
            }).catch(err =>{
                 console.log(err)
                 setError(err)
                 setViewLoader(false)
                });
        }

        const [selectedLoginId, setSelectedLoginId] = useState(null);

        const toggleLoginDetails = (id) => {
          setSelectedLoginId(selectedLoginId === id ? null : id);
        };




        let load

        if(viewLoader==true){
            load=   <div className="per">
        
            <div className="loader">
            <div className="overlayLo"> 
                <div className="loaderP">
                <Loader/>
                </div>
                </div>
                </div>
                </div>
        }else{
            load=''
        }



        if(loader){
            return(
                <div className="per">
                
                <div className="loader">
                <div className="overlayLo"> 
                    <div className="loaderP">
                    <Loader/>
                    </div>
                    </div>
                    </div>
                    </div>
            )
        }else if(error){
            return (<div>Une erreur est survenue : {error.message}</div>)
        }
else{        
        if(largeurEcran>= 510){
    return(
        <>
        <div className='wini'>
        <Navbar/>
        <h1 className='win'>La page de gestion des candidats</h1><br/>
        {load}
        <div className='inove'>
        <div className="search">
             <input type="text" className="recherche" name="searchBar" id="searchBar" placeholder="Rechercher" onChange={e=>setQuery(e.target.value)}></input>
        </div>
        
        <div className='selvil'  >
             
             <select name="poste" className='sel' onChange={e => setValue(e.target.value)} >
             <option value={'Enregistré ce mois'}>Enregistré ce mois</option>
             <option value={'Tous les Candidats'}>Tous les Candidats</option>
            
            <option value={'Enregistré cette année'}>Enregistré cette année</option>
            
            <option value={'Qui ont retiré leurs permis'}>Qui ont retiré leurs permis</option>
                  
           </select>
           <button className='butonSF2' onClick={handleTout}>Appliquer</button>
           <br/><br/>

           <h4 className='che'> Recherche personnalisé </h4>
           <div className='person'>
           <input type='date' value={date1} className='oix' onChange={e => setDate1(e.target.value)}></input> <input type='date' value={date2} className='oix' onChange={e => setDate2(e.target.value)} ></input> 
           <button type='submit' onClick={handlePropose} className='somi'>APPLIQUER</button>
           
           </div>
           </div>
        </div>

        <br/><br/>
    <div className=''>
        <div className=''>
             
        <div className="card-list20">
        {
                        candidat.filter((data) => data.nom.toLowerCase().includes(query) || data.prenom.toLowerCase().includes(query)).reverse().map((data, i)=> (
                            <div className={`card20 ${selectedLoginId === data.id ? 'card-expanded' : ''}`} key={i}>
                                         <div className="image-container8">
                 <img src={data.image ? `${data.image}` : (data.sexe === 'Masculin' ? moni : secre)} alt="Candidat" className="profile-image" />

         
          </div>
                      
                         <div className='info'>
                            <p className='name'> {data.nom} {data.prenom}</p>
                            <a href={`tel:+229${data.contact}`} className="contact-link">
            <p className="contacts">
              <FaPhoneAlt className="phone-icon" /> {data.contact}
            </p>
          </a>
               
<div className="login-section">
{selectedLoginId===data.id && (
            <div className="login-details">
             <button onClick={() => toggleLoginDetails(data.id)} className="eye-button">
                  {selectedLoginId === data.id ? '👁️' : '👁️‍🗨️'}
                </button>
              <p>Username: {data.user_name}</p>
              <p>Password: {data.password}</p>
              
            </div>
          )}

               {selectedLoginId !== data.id && (
              <p className="login">
                Login***
                <button onClick={() => toggleLoginDetails(data.id)} className="eye-button">
                  {selectedLoginId === data.id ? '👁️' : '👁️‍🗨️'}
                </button>
              </p>
            )}

</div>        
                            <Link to={'/pageCandidat/'+ data.id} >    <button className="modifier-btn">VOIR</button>  </Link>
                            </div>
                        </div>
                    
                        ))
                      } 
                                 </div>
                                 
        </div>
        
    </div>
    <br/>
    </div>
    <Footer/>
    </>
    )
                    }else{
                        return(
                            <>
                            <div className='wini'>
                                
                            <Navbar/>
                            <h1 className='win'>La page de gestion des candidats</h1><br/>
                            {load}
                            <div className='barContener'>
                    
                    {/* Barre de recherche */}
                    <div className='barChercheT'>
                        <input type="text"className='rechBar'  name="searchBar" id="searchBar" placeholder="Rechercher" onChange={e => setQuery(e.target.value)}></input>
                    </div>
                    
                    {/* Barre de sélection avec bouton Appliquer */}
                    <div className='barChoixT'>
                        <select name="poste" className='choosee' onChange={e => setValue(e.target.value)}>
                        <option value={'Enregistré ce mois'}>Enregistré ce mois</option>
                                <option value={'Tous les Candidats'}>Tous les Candidats</option>
                                <option value={'Enregistré cette année'}>Enregistré cette année</option>
                                
                                <option value={'Qui ont retiré leurs permis'}>Qui ont retiré leurs permis</option>
                        </select>
                        {/* Bouton Appliquer pour le select */}
                        <button className='butonSF' onClick={handleTout}>Appliquer</button>
                    </div>
                    
                    {/* Barre de recherche personnalisée */}
                    <div className='persBarT'>
                        
                        <input type='date' className='perSF' value={date1} onChange={e => setDate1(e.target.value)}></input>
                        <input type='date' className='perSF' value={date2} onChange={e => setDate2(e.target.value)}></input>
                        <button type='submit' className='butonSF' onClick={handlePropose}>APPLIQUER</button>
                    </div>
                    
                    </div>
                         
                    
                        <div className=''>
                            <div className=''>
                                 
                                 <div className="card-list20">
                                 {
                        candidat.filter((data) => data.nom.toLowerCase().includes(query) || data.prenom.toLowerCase().includes(query)).reverse().map((data, i)=> (
                            <div className={`card20 ${selectedLoginId === data.id ? 'card-expanded' : ''}`} key={i}>
                                          <div className="image-container8">
                 <img src={data.image ? `${data.image}` : (data.sexe === 'Masculin' ? moni : secre)} alt="Candidat" className="profile-image" />

         
          </div>
                        
                         <div className='info'>
                            <p className='name'> {data.nom} {data.prenom}</p>
                            <a href={`tel:+229${data.contact}`} className="contact-link">
            <p className="contacts">
              <FaPhoneAlt className="phone-icon" /> {data.contact}
            </p>
          </a>
               
<div className="login-section">
{selectedLoginId===data.id && (
            <div className="login-details">
             <button onClick={() => toggleLoginDetails(data.id)} className="eye-button">
                  {selectedLoginId === data.id ? '👁️' : '👁️‍🗨️'}
                </button>
              <p>Username: {data.user_name}</p>
              <p>Password: {data.password}</p>
              
            </div>
          )}

               {selectedLoginId !== data.id && (
              <p className="login">
                Login***
                <button onClick={() => toggleLoginDetails(data.id)} className="eye-button">
                  {selectedLoginId === data.id ? '👁️' : '👁️‍🗨️'}
                </button>
              </p>
            )}

</div>        
                            <Link to={'/pageCandidat/'+ data.id} >    <button className="modifier-btn">VOIR</button>  </Link>
                            </div>
                        </div>
                    
                        ))
                      } 
                                 </div>
                                 
                            </div>
                        </div>
                        <br/>
                        </div>
                    <Footer/>
                        </>   
 
                        )
                    }
                }
}