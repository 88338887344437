import React,{ useEffect, useState} from 'react';
import axios from 'axios';
import Navbar from 'F:/projet/first/src/components/navbar';
import Loader from "../components/loader";
import Mod from './mod.png'
import { Link } from "react-router-dom";
import Footer from '../components/footer';
import moni from 'F:/projet/first/src/components/image/moni.png';
import secre from 'F:/projet/first/src/components/image/images.png'
import Cookies from 'js-cookie';
import { FaPhoneAlt } from 'react-icons/fa';
import Modal from '../components/popup';
import ErrorModal from '../components/errorpopup';
export default function Candidat(){
    var date= new Date();
    const headers = {
        'Content-Type': 'application/json',
        'Access-Control-Allow-Origin': 'https://gestauto.seg.solutions',
        'credentials': 'include',
      };
   const years= date.getFullYear()
  var  month=  date.getMonth()+1
  var autre= date.toISOString().split('T')[0] 
    const[value,setValue]= useState('')
    const [date1, setDate1]= useState('')
    var autre= date.toISOString().split('T')[0]
    const days= date.getDate()
    var datee= new Date(years,month-2,days)
    var autre2= datee.toISOString().split('T')[0] 
    const [error, setError] = useState(null);
    const [loader, setLoader]= useState(true)
  const [date2, setDate2]= useState('')
    const [candidat, setCandidat] = useState([])
    const [largeurEcran, setLargeurEcran] = useState(window.innerWidth);
 const [viewLoader, setViewLoader] = useState(false)
    const [gestauto,setGestauto]= useState(Cookies.get('gestauto'))
    const [cont,setCont]= useState(1000)
    const [mie, setMie]= useState(Cookies.get('mie'))

    const [select1,setSelect1]= useState(Cookies.get('select1'))
    const [select2,setSelect2]= useState(Cookies.get('select2'))









    
        useEffect(()=>{
            if(select1=== undefined || select2===undefined){

              let date1 = autre2
              let date2 = autre
              console.log('les date', date1, date2)
            axios.post('https://gestauto-56bfcbe4b876.herokuapp.com/secreCandParDef',{date1,date2,gestauto,mie}, { headers})
            .then(res => {
                setCandidat(res.data)
                setLoader(false)
        })
            .catch(err =>{ console.log(err)
                setError(err)
                setLoader(false)
            });
            setCont('')
        }else if(select1 || select2){

           

            axios.post('https://gestauto-56bfcbe4b876.herokuapp.com/secreVvoir', {select1,select2,gestauto,mie}, { headers})
            .then(res => {
                setDate1(select1)
                setDate2(select2)
               setCandidat(res.data)
               
               setLoader(false)
                
            }).catch(err => {
                console.log(err)
                setError(err)
                setLoader(false)
               
            });
        }
        }, [])
      
        const mettreAJourLargeurEcran = () => {
            setLargeurEcran(window.innerWidth);
        
          };

     const handleDelete = async(id) => {  
       try {
        await axios.delete('https://gestauto-56bfcbe4b876.herokuapp.com/candidat/'+id, { headers})
        window.location.reload()
       }catch(err) {
        console.log(err);
       }
     }

     const [query, setQuery]= useState(""); 
  
         
         function handleTout(event){
            setViewLoader(true)
            event.preventDefault();
            if(value=='Enregistré ce mois'){
                
                axios.post('https://gestauto-56bfcbe4b876.herokuapp.com/candidatThis',{month,years,gestauto,mie}, { headers})
                .then(res => {
                    setCandidat(res.data)
                    setViewLoader(false)
                })
                .catch(err => {
                    console.log(err)
                    setError(err)
                    setLoader(false)
                    setViewLoader(false)
                });
                }
            else if(value=='Enregistré cette année'){
                
                axios.post('https://gestauto-56bfcbe4b876.herokuapp.com/candidatThisYears',{years,gestauto,mie}, { headers})
                .then(res => {
                    setCandidat(res.data)
                    setViewLoader(false)
                })
                .catch(err => {
                    console.log(err)
                    setError(err)
                    setLoader(false)
                    setViewLoader(false)
                });
            }else if(value=='Tous les Candidats'){
                
                axios.get('https://gestauto-56bfcbe4b876.herokuapp.com/candidat?id='+mie+'', { headers})
                .then(res => {
                    setCandidat(res.data)
                    setViewLoader(false)
                })
                .catch(err => {
                    console.log(err)
                    setError(err)
                    setLoader(false)
                    setViewLoader(false)
                });
            }else if(value=='Qui ont retiré leurs permis'){
                
                axios.post('https://gestauto-56bfcbe4b876.herokuapp.com/candidatPermis',{gestauto,mie}, { headers})
                .then(res => {
                    setCandidat(res.data)
                    setViewLoader(false)
                })
                .catch(err => {
                    console.log(err)
                    setError(err)
                    setLoader(false)
                    setViewLoader(false)
                });
            }else{
              axios.post('https://gestauto-56bfcbe4b876.herokuapp.com/candidatThis',{month,years,gestauto,mie}, { headers})
              .then(res => {
                  setCandidat(res.data)
                  setViewLoader(false)
              })
              .catch(err => {
                  console.log(err)
                  setError(err)
                  setLoader(false)
                  setViewLoader(false)
              });
            }
           
        }

        useEffect(()=>{
            setDate1(autre2)
            setDate2(autre)
        }, [])

        function handlePropose(event){
            event.preventDefault();
            Cookies.set('select1',date1)
            Cookies.set('select2',date2)
            setViewLoader(true)
            axios.post('https://gestauto-56bfcbe4b876.herokuapp.com/personCandidat', {date1,date2,gestauto,mie}, { headers})
            .then(res => {
               setCandidat(res.data)
               
               setViewLoader(false)
                
            }).catch(err => {
                console.log(err)
                setError(err)
                setLoader(false)
                setViewLoader(false)
            });
        }



        useEffect(() => {
        
            window.addEventListener('resize', mettreAJourLargeurEcran);
        
           
            return () => {
              window.removeEventListener('resize', mettreAJourLargeurEcran);
              
            };
           
          }, []);
          const [selectedLoginId, setSelectedLoginId] = useState(null);

          const toggleLoginDetails = (id) => {
            setSelectedLoginId(selectedLoginId === id ? null : id);
          };
          

          














          const [ID, setID]=useState('')

          const[modal, setModal]= useState(false) 
          const toggleModal = ()=> {
              setModal(!modal)
          }
           
    
          const [isModalOpen, setIsModalOpen] = useState(false);
          const [currentId, setCurrentId] = useState(null);
          const [fileInputId, setFileInputId] = useState(null); // Pour stocker l'ID du champ de fichier
        
          const [isErrorModalOpen, setIsErrorModalOpen] = useState(false);
   
    
     
     // Fonction pour ouvrir la boîte de dialogue d'importation de fichier
     function handleImportClick() {
       const fileInput = document.getElementById(`file-input-${ID}`);
       if (fileInput) {
         fileInput.click();
       } else {
         console.error(`File input with id 'file-input-${ID}' not found`);
       }
     }

     /*
       // Fonction pour ouvrir la boîte de dialogue d'importation de fichier
       function handleImportClick2(id) {
        const fileInput = document.getElementById(`file-input-${id}`);
        if (fileInput) {
          fileInput.click();
        } else {
          console.error(`File input with id 'file-input-${id}' not found`);
        }
      }

*/

// Fonction pour ouvrir la boîte de dialogue d'importation de fichier


      

      function handleImportClick2(id) {

        if (isIOS) {
          openCameraIOS2(id); // Ouvrir l'appareil photo pour iOS après la confirmation
        } else {
          const fileInput = document.getElementById(`file-input-${id}`);
          if (fileInput) {
            fileInput.click(); // Ouvrir le gestionnaire de fichiers pour les autres plateformes
          } else {
            console.error(`File input with id 'file-input-${id}' not found`);
          }
        }

      
      }

     
     // Fonction pour gérer l'upload de fichier
     function handleImageUpload(event, id) {
    
       const file = event.target.files[0];
       if (file) {
/*
        if (file.size > 2 * 1024 * 1024) {
          setIsErrorModalOpen(true); // Ouvrir la modale d'erreur
          return;
        }
*/
        setViewLoader(true)
         const formData = new FormData();
         formData.append('image', file);
         formData.append('id', id);
         if(affInscrit){
          toggleInscrit()
         }
         
         axios.post('https://gestauto-56bfcbe4b876.herokuapp.com/upload-image', formData)
           .then(response => {
            setTimeout(() => {
             
              window.location.reload(); // Recharger la page après 3 secondes
            }, 100);
           
           })
           .catch(error => {
             console.error("Erreur lors de l'upload de l'image:", error);
           });
       }
     }
     
     

     
     
     
     
     
     
     
     function openCamera() {
       const video = document.createElement('video');
       const canvas = document.createElement('canvas');
       const context = canvas.getContext('2d');
     
       navigator.mediaDevices.getUserMedia({ video: true })
         .then(stream => {
           video.srcObject = stream;
           video.play();
     
           // Créer une modal personnalisée
           const modal = document.createElement('div');
           modal.className = 'camera-modal-custom';
           document.body.appendChild(modal);
           modal.appendChild(video);
     
           // Bouton pour capturer l'image
           const captureButton = document.createElement('button');
           captureButton.className = 'bouton-camera-capture';
           captureButton.innerHTML = '<i class="fas fa-camera"></i>'; // Icône de capture
           modal.appendChild(captureButton);
     
           // Bouton pour annuler
           const cancelButton = document.createElement('button');
           cancelButton.className = 'bouton-camera-annuler';
           cancelButton.innerText = 'Annuler';
           modal.appendChild(cancelButton);
     
           // Centrer l'icône de la caméra
           video.style.display = 'block';
           video.style.margin = '0 auto';
     
           // Capturer l'image
           captureButton.addEventListener('click', () => {
             canvas.width = 300;
             canvas.height = 300;
             context.drawImage(video, 0, 0, canvas.width, canvas.height);
     
             video.style.display = 'none';
             modal.appendChild(canvas);
     
                
     
             // Bouton pour valider l'image
             const confirmButton = document.createElement('button');
             confirmButton.className = 'bouton-camera-confirmer';
             confirmButton.innerHTML = '<i class="fas fa-check"></i>';
             modal.appendChild(confirmButton);
     
             // Bouton pour reprendre la capture
             const retryButton = document.createElement('button');
             retryButton.className = 'bouton-camera-reprendre';
             retryButton.innerHTML = '<i class="fas fa-redo"></i>';
             modal.appendChild(retryButton);
     
             // Valider et envoyer l'image
             confirmButton.addEventListener('click', () => {
               canvas.toBlob(blob => {
                 if (blob) {
                   const formData = new FormData();
                   formData.append('image', blob, 'profile-image.jpg');
                   formData.append('id', ID);
     toggleInscrit()
                   axios.post('https://gestauto-56bfcbe4b876.herokuapp.com/upload-image', formData)
                     .then(() => {
                           
     
                  
                       
                     window.location.reload()
                     
                     })
                     .catch(error => console.error('Erreur lors de l\'upload:', error));
     
                   video.srcObject.getTracks().forEach(track => track.stop());
                   modal.remove();
                 }
               }, 'image/jpeg');
             });
     
             // Masquer le bouton "Annuler" après capture
             cancelButton.style.display = 'none';
     
             // Reprendre la capture
             retryButton.addEventListener('click', () => {
               confirmButton.remove();
               retryButton.remove();
               canvas.remove();
                
               video.style.display = 'block';
               cancelButton.style.display = 'block'; // Réafficher le bouton "Annuler"
               captureButton.style.display = 'block'; // Faire réapparaître l'icône caméra
             });
     
             captureButton.style.display = 'none'; // Masquer l'icône caméra après capture
           });
     
           cancelButton.addEventListener('click', () => {
             video.srcObject.getTracks().forEach(track => track.stop());
             modal.remove();
           });
         })
         .catch(error => console.error('Erreur lors de l\'accès à la caméra:', error));
     }
     
     
     
     



// Détecter si l'utilisateur est sur iOS
const isIOS = /iPhone|iPad|iPod/i.test(navigator.userAgent);

// Fonction pour ouvrir la caméra sur iOS
function openCameraIOS() {
  if (isIOS) {
    const fileInput = document.createElement('input');
    fileInput.type = 'file';
    fileInput.accept = 'image/*;capture=camera'; // Ouvre directement l'appareil photo sur iOS
    fileInput.style.display = 'none'; // Cacher l'input file visuellement

    // Gérer la sélection d'une image via l'appareil photo
    fileInput.onchange = function(event) {
      const file = event.target.files[0];
      if (file) {
        toggleInscrit()
        setViewLoader(true)
        const formData = new FormData();
        formData.append('image', file, 'profile-image.jpg');
        formData.append('id', ID); // Remplacez par votre ID de candidat

        axios.post('https://gestauto-56bfcbe4b876.herokuapp.com/upload-image', formData)
          .then(response => {
            setTimeout(() => {
            
              console.log('Image uploadée avec succès');
              window.location.reload(); // Recharger la page après 3 secondes
            }, 100);
          
          })
          .catch(error => console.error('Erreur lors de l\'upload:', error));
      }
    };

    // Ajouter le champ input au document et le déclencher
    document.body.appendChild(fileInput);
    fileInput.click(); // Simuler un clic pour ouvrir la caméra
  }
}

const MAX_FILE_SIZE = 2 * 1024 * 1024; // 2 Mo en bytes
// Fonction pour ouvrir la caméra sur iOS
function openCameraIOS2(id) {
  if (isIOS) {
    const fileInput = document.createElement('input');
    fileInput.type = 'file';
    fileInput.accept = 'image/*;capture=camera'; // Ouvre directement l'appareil photo sur iOS
    fileInput.style.display = 'none'; // Cacher l'input file visuellement

    // Gérer la sélection d'une image via l'appareil photo
    fileInput.onchange = function(event) {
      const file = event.target.files[0];
      if (file) {
        if(affInscrit){
          toggleInscrit()
         }
/*
         if (file.size > MAX_FILE_SIZE) {
          // Afficher la modale d'erreur
          setIsErrorModalOpen(true);
          return;
        }
     */
        setViewLoader(true)
        const formData = new FormData();
        formData.append('image', file, 'profile-image.jpg');
        formData.append('id', id); // Remplacez par votre ID de candidat

        axios.post('https://gestauto-56bfcbe4b876.herokuapp.com/upload-image', formData)
          .then(response => {
            setTimeout(() => {
            
              console.log('Image uploadée avec succès');
              window.location.reload(); // Recharger la page après 3 secondes
            }, 200);
          
          })
          .catch(error => console.error('Erreur lors de l\'upload:', error));
      }
    };

    // Ajouter le champ input au document et le déclencher
    document.body.appendChild(fileInput);
    fileInput.click(); // Simuler un clic pour ouvrir la caméra
  }
}




function openCameraByDevice() {
// Appel de la fonction pour ouvrir la caméra sur iOS
if (isIOS) {
  openCameraIOS(); // Utilise l'input type file pour iOS
} else {
  // Sinon, utilisez votre code habituel avec getUserMedia pour Android
  openCamera();
}

}






     
     
     
     
     
     const[affInscrit, setAffInscrit]= useState('')
                   
     const[ins, setIns]= useState(false) 
     
     const toggleInscrit = ()=> {
     
         setAffInscrit('')
         setIns(!ins)
      
     }
     
     
     if(affInscrit!== ''){
       setID(affInscrit)
      toggleInscrit()
      
     } 














        let load

        if(viewLoader==true){
            load=   <div className="avt">
            <div className="rin">
              <div className="chm">
                <Loader/>
              </div>
            </div>
          </div>
        }else{
            load=''
        }
        


if(loader){
    return(
        <div className="per">
        
        <div className="loader">
        <div className="overlayLo"> 
            <div className="loaderP">
            <Loader/>
            </div>
            </div>
            </div>
            </div>
    )
}else if(error){
    return (<div>Une erreur est survenue : {error.message}</div>)
}
else{
    if(largeurEcran>= 1024){
    return(
        <>
        <div className='wini'>
            
        <Navbar/>
        <h1 className='win'>La page de gestion des candidats</h1><br/>
        {load}
        <div className='inove'>
        <div className="search">
             <input type="text" className="recherche" name="searchBar" id="searchBar" placeholder="Rechercher" onChange={e=>setQuery(e.target.value)}></input>
        </div>

        <div className='selvil'  >
             
             <select name="poste" className='sel' onChange={e => setValue(e.target.value)} >
             
            <option value={'Enregistré ce mois'}>Enregistré ce mois</option>
            <option value={'Tous les Candidats'}>Tous les Candidats</option>
            <option value={'Enregistré cette année'}>Enregistré cette année</option>
            
            <option value={'Qui ont retiré leurs permis'}>Qui ont retiré leurs permis</option>
            

                     
                
           </select>
           <button className='butonSF2' onClick={handleTout}>Appliquer</button>
           <br/><br/>
           <h4 className='che'> Recherche personnalisée </h4>
           <div className='person'>
           <input type='date' value={date1} className='oix' onChange={e => setDate1(e.target.value)}></input> <input type='date' value={date2}  className='oix' onChange={e => setDate2(e.target.value)} ></input> <br/>
           <button type='submit' onClick={handlePropose} className='butonSF3'>APPLIQUER</button>
           </div>
           </div>
       
       </div>

    <div className=''>
        <div className=''>
             <Link to="/create" className='yello'><button className='ajouter'> AJOUTER UN CANDIDAT</button> </Link><br/><br/>
             <div className="card-list20">
             {
                        candidat.filter((data) => data.nom.toLowerCase().includes(query) || data.prenom.toLowerCase().includes(query)).reverse().map((data, i)=> (
                            <div className={`card20 ${selectedLoginId === data.id ? 'card-expanded' : ''}`} key={i}>
                             
                        
          {/* ici  */}              
                        
          <div className="image-container8">
                 <img src={data.image ? `${data.image}` : (data.sexe === 'Masculin' ? moni : secre)} alt="Candidat" className="profile-image" />

           <div className="camera-icon" onClick={   e => setAffInscrit(data.id)}>
              📷
            </div>
          </div>

{/* Champ caché pour l'upload de fichier */}
         <input
           type="file"
           id={`file-input-${data.id}`}
           style={{ display: 'none' }}
         onChange={(event) => handleImageUpload(event, data.id)}
         />      
                      
            {/*fin */}           
                        
                        
                         <div className='info'>
                            <p className='name'> {data.nom} {data.prenom}</p>
                            <a href={`tel:+229${data.contact}`} className="contact-link">
            <p className="contacts">
              <FaPhoneAlt className="phone-icon" /> {data.contact}
            </p>
          </a>
               
<div className="login-section">
{selectedLoginId===data.id && (
            <div className="login-details">
             <button onClick={() => toggleLoginDetails(data.id)} className="eye-button">
                  {selectedLoginId === data.id ? '👁️' : '👁️‍🗨️'}
                </button>
              <p>Username: {data.user_name}</p>
              <p>Password: {data.password}</p>
              
            </div>
          )}

               {selectedLoginId !== data.id && (
              <p className="login">
                Login***
                <button onClick={() => toggleLoginDetails(data.id)} className="eye-button">
                  {selectedLoginId === data.id ? '👁️' : '👁️‍🗨️'}
                </button>
              </p>
            )}

</div>        

{/*ici */}


{ins && ( <>
    <div className="overlay-pop"></div>
    <div className="fenetre-modal">
      <div className="modal-entete">
      
      </div>
      <div className="modal-corps">
      <div className="upload-options">
    {/* Option pour importer une image */}
    <button className="import-btn" onClick={() => handleImportClick()}>
      📁 Importer une image
    </button>

    {/* Option pour prendre une photo */}
    <button className="camera-btn" onClick={() => openCamera()}>
      📷 Prendre une photo
    </button>
  </div>
      </div>
      <div className="boutons-modal">
        <Link onClick={toggleInscrit}>
          <button className="bouton-annuler">QUITTER</button>
        </Link>
      </div>
    </div>
  </>)} 
{/*
  <ErrorModal 
        isOpen={isErrorModalOpen} 
        onClose={() => setIsErrorModalOpen(false)} 
      />
 */}
{/*fin */}


                            <Link to={'/pageCandidat/'+ data.id} >    <button className="modifier-btn">VOIR</button>  </Link>
                            </div>
                        </div>
                    
                        ))
                      } 
             </div>

        </div>
    </div>
    <br/>
    </div>
<Footer/>
    </>
    )
}else{
    return(
        <>
        <div className='wini'>
            
        <Navbar/>
        <h1 className='win'>La page de gestion des candidats</h1><br/>
        {load}
        <div className='barContener'>

{/* Barre de recherche */}
<div className='barChercheT'>
    <input type="text"className='rechBar'  name="searchBar" id="searchBar" placeholder="Rechercher" onChange={e => setQuery(e.target.value)}></input>
</div>

{/* Barre de sélection avec bouton Appliquer */}
<div className='barChoixT'>
    <select name="poste" className='choosee' onChange={e => setValue(e.target.value)}>
    <option value={'Enregistré ce mois'}>Enregistré ce mois</option>
            <option value={'Tous les Candidats'}>Tous les Candidats</option>
            <option value={'Enregistré cette année'}>Enregistré cette année</option>
            
            <option value={'Qui ont retiré leurs permis'}>Qui ont retiré leurs permis</option>
    </select>
    {/* Bouton Appliquer pour le select */}
    <button className='butonSF' onClick={handleTout}>Appliquer</button>
</div>

{/* Barre de recherche personnalisée */}
<div className='persBarT'>
    
    <input type='date' className='perSF' value={date1} onChange={e => setDate1(e.target.value)}></input>
    <input type='date' className='perSF' value={date2} onChange={e => setDate2(e.target.value)}></input>
    <button type='submit' className='butonSF' onClick={handlePropose}>APPLIQUER</button>
</div>

</div>
     
    <div className=''>
        <div className=''>
             <Link to="/create" className='yello'><button className='ajouter'> AJOUTER UN CANDIDAT</button> </Link><br/><br/>
             
             <div className="card-list20">
             {
                        candidat.filter((data) => data.nom.toLowerCase().includes(query) || data.prenom.toLowerCase().includes(query)).reverse().map((data, i)=> (
                            <div className={`card20 ${selectedLoginId === data.id ? 'card-expanded' : ''}`} key={i}>
                  
                        
                                    
          {/* ici  */}              
                        
          <div className="image-container8">
                 <img src={data.image ? `${data.image}` : (data.sexe === 'Masculin' ? moni : secre)} alt="Candidat" className="profile-image" />

           <div className="camera-icon" onClick={ () => isIOS ? handleImportClick2(data.id) : handleImportClick2(data.id) }>
              📷
            </div>
          </div>

{/* Champ caché pour l'upload de fichier */}
         <input
           type="file"
           id={`file-input-${data.id}`}
           style={{ display: 'none' }}
         onChange={(event) => handleImageUpload(event, data.id)}
         />      
                      
            {/*fin */}           
                           
                        
                        
                        
                        
                         <div className='info'>
                            <p className='name'> {data.nom} {data.prenom}</p>
                         
                      <a href={`tel:+229${data.contact}`} className="contact-link">
            <p className="contacts">
              <FaPhoneAlt className="phone-icon" /> {data.contact}
            </p>
          </a>
               
<div className="login-section">
{selectedLoginId===data.id && (
            <div className="login-details">
             <button onClick={() => toggleLoginDetails(data.id)} className="eye-button">
                  {selectedLoginId === data.id ? '👁️' : '👁️‍🗨️'}
                </button>
              <p>Username: {data.user_name}</p>
              <p>Password: {data.password}</p>
              
            </div>
          )}

               {selectedLoginId !== data.id && (
              <p className="login">
                Login***
                <button onClick={() => toggleLoginDetails(data.id)} className="eye-button">
                  {selectedLoginId === data.id ? '👁️' : '👁️‍🗨️'}
                </button>
              </p>
            )}

</div>

{/*ici */}


{ins && ( <>
    <div className="overlay-pop"></div>
    <div className="fenetre-modal">
      <div className="modal-entete">
      
      </div>
      <div className="modal-corps">
      <div className="upload-options">
    {/* Option pour importer une image */}
    <button className="import-btn" onClick={() => handleImportClick()}>
      📁 Importer une image
    </button>

    {/* Option pour prendre une photo */}
    <button className="camera-btn" onClick={() => openCameraByDevice()}>
      📷 Prendre une photo
    </button>
  </div>
      </div>
      <div className="boutons-modal">
        <Link onClick={toggleInscrit}>
          <button className="bouton-annuler">QUITTER</button>
        </Link>
      </div>
    </div>
  </>)} 


 {/* Modale pour confirmer l'upload 
 <Modal 
        isOpen={isModalOpen} 
        onClose={() => setIsModalOpen(false)} 
        onConfirm={handleConfirmOpenFileDialog} 
      />
*/}
   {/* Modale pour afficher l'erreur 
   <ErrorModal 
        isOpen={isErrorModalOpen} 
        onClose={() => setIsErrorModalOpen(false)} 
      />
*/}
{/*fin */}
                            <Link to={'/pageCandidat/'+ data.id} >    <button className="modifier-btn">VOIR</button>  </Link>
                           
                            </div>
                        </div>
                    
                        ))
                      } 
             </div>
             
        </div>
    </div>
    <br/>
    </div>
<Footer/>
    </>
    )

}
}
}