import React, {useEffect, useState} from "react";
import * as FaIcons from "react-icons/fa";
import * as AiIcons from "react-icons/ai";
import { Link,NavLink, useNavigate,  useLocation } from "react-router-dom";
import { sidedata } from "./sidedata";
import { IconContext } from "react-icons";
import images from './image/images.png';
import moni from './image/moni.png';
import { sidedataMoni } from "./sidedataMoni";
import log from "./image/log.png"
import Cookies from 'js-cookie';
import axios from 'axios';
import logo from './image/logo4.jpg'
export default function Navbar(){
    const headers = {
        'Content-Type': 'application/json',
        'Access-Control-Allow-Origin': 'https://gestauto.seg.solutions',
        'credentials': 'include',
      };
   const navigate= useNavigate()
    const [sidebar, setSidebar]= useState(false)
    const showSidebar = () => setSidebar(!sidebar)
    const[profil, setProfil]= useState(false)
    const showProfil = () => setProfil(!profil)
    const [secretaire, setSecretaire] = useState([])
    const [lu,setLu] = useState(Cookies.get('token'))
    const [tok, setTok]= useState(Cookies.get('token'))
    const [titre]= useState(Cookies.get('titre'))
    const [gestauto,setGestauto]= useState(Cookies.get('gestauto'))
    const [mie, setMie]= useState(Cookies.get('mie'))
    const [certain, setCertain]= useState('')
    const [largeurEcran, setLargeurEcran] = useState(window.innerWidth);
    const upTok =()=>{
         setLu(Cookies.get('token'))

        
    }
    const mettreAJourLargeurEcran = () => {
        setLargeurEcran(window.innerWidth);
    
      };


      const [old, setOld]= useState('')
    const [recup, setRecup]= useState('')
    const [new1, setNew1]= useState('')
    const [new2, setNew2]= useState('')
    const [info1, setInfo1]= useState('')
    const [info2, setInfo2]= useState('')



        useEffect(()=>{
        
        axios.get('https://gestauto-56bfcbe4b876.herokuapp.com/navbar?id='+gestauto+'&titre='+titre+'', { headers })
        .then(res => setSecretaire(res.data))
        .catch(err => console.log(err));
    }, [])
    
    useEffect(()=>{
        if(!Cookies.get('token')){
            Cookies.remove('mie')
            Cookies.remove('titre')
            Cookies.remove('gestauto')
           navigate('/')
        }
   }, [])

   const logout = () =>{
   
    Cookies.remove('token')
    Cookies.remove('mie')
    Cookies.remove('titre')
    Cookies.remove('gestauto')
    Cookies.remove('default')
    Cookies.remove('default2')
    Cookies.remove('defaultCode')
    Cookies.remove('defaultCode2')
    Cookies.remove('select1')
        Cookies.remove('select2')
   navigate('/')
   window.location.reload();
}

if(tok!= lu){
    Cookies.remove('token')
    Cookies.remove('mie')
    Cookies.remove('titre')
    Cookies.remove('gestauto')
    window.location.reload();
    navigate('/')

}

useEffect(() => {
        
    window.addEventListener('resize', mettreAJourLargeurEcran);

   
    return () => {
      window.removeEventListener('resize', mettreAJourLargeurEcran);
      
    };
   
  }, []);
// Recuperer token
useEffect(()=>{
    axios.post('https://gestauto-56bfcbe4b876.herokuapp.com/setToken' ,{gestauto,titre}, { headers })
    .then(res => 
        {
            
            setTok(res.data[0].token)
            

        })
    .catch(err => console.log(err));
}, [])


useEffect(()=>{
    axios.post('https://gestauto-56bfcbe4b876.herokuapp.com/cer' ,{gestauto,titre,mie,tok}, { headers })
    .then(res => 
        {
            setCertain(res.data.message);

        })
    .catch(err => console.log(err));
}, [])

    if(certain=='denied'){
        Cookies.remove('token')
        Cookies.remove('mie')
        Cookies.remove('titre')
        Cookies.remove('gestauto')
        window.location.reload();
        navigate('/')
    }
         var controleAnnexe= 1;  


//modification password



const[valid, setValid]= useState('')
              
const[valid2, setValid2]= useState('')
const[supMod, setSupMod]= useState(false) 

const toggleSup = ()=> {
  
 
  setSupMod(!supMod)
  setValid('')
 
}


if(valid!= ''){
 toggleSup()
 


} 



              

const[supMod2, setSupMod2]= useState(false) 

const toggleSup2 = ()=> {
  
 
  setSupMod2(!supMod2)
  setValid2('')
  
}


if(valid2!= ''){
 toggleSup2()
 


} 




function handleModf(event){
    const id= gestauto
    event.preventDefault();
    const currentPassword = secretaire.map((data) => data.password)[0];
    
    if(currentPassword===old){
    
        if(new1===new2){
            setInfo2('')
            axios.put('https://gestauto-56bfcbe4b876.herokuapp.com/upPassword/'+id, {new1,titre}, { headers})
            .then(res => {
                    
        axios.get('https://gestauto-56bfcbe4b876.herokuapp.com/navbar?id='+gestauto+'&titre='+titre+'', { headers })
        .then(res => setSecretaire(res.data))
        .catch(err => console.log(err));
                toggleSup()

            setValid2('on')
            }).catch(err => console.log(err));
        }else{
           
            setInfo2('Incorrecte')
            setTimeout(() => setInfo2(''), 3000)
        }
    }else{
        setInfo1('Incorrecte')
        setTimeout(() => setInfo1(''), 3000)
    }
}






const [menuVisible, setMenuVisible] = useState(false);

const toggleMenu = () => {
    setMenuVisible(!menuVisible);
  
};



const [showOldPassword, setShowOldPassword] = useState(false);
const [showNewPassword, setShowNewPassword] = useState(false);
const [showConfirmPassword, setShowConfirmPassword] = useState(false);


const toggleOldPasswordVisibility = () => setShowOldPassword(!showOldPassword);
const toggleNewPasswordVisibility = () => setShowNewPassword(!showNewPassword);
const toggleConfirmPasswordVisibility = () => setShowConfirmPassword(!showConfirmPassword);




useEffect(() => {
    const handleClickOutside = (event) => {
        if (menuVisible && !document.querySelector('.carD').contains(event.target)) {
            setMenuVisible(false);
        }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
        document.removeEventListener('mousedown', handleClickOutside);
    };
}, [menuVisible]);


const location = useLocation();


      if(secretaire.map((data)=>(data.poste))=="secretaire"){
      
       if(largeurEcran>= 1000){

       
        return(
        
            <>
            
            <IconContext.Provider value={{color:'#fff'}}>
            <div className="barHaut">


            <div className="lep">
                
                
             <img src={images} className="user" onClick={showProfil} />



             {supMod && (<div className='secretModalSup'>
            <div className='secretOverlay'></div>
            <div className='secretModalContent'>
                <div className="secretContainerModal"> 
                    <h3 className="secretFormTitle">MODIFIER MOT DE PASSE</h3>
                    
                    <div className='secretSupD'>
                        <input type="text" name="nom" className="secretInputCmot" id="nom" value={secretaire.map((data) => (data.nom))} readOnly />
                        <input type="text" name="prenom" className="secretInputCmot" id="prenom" value={secretaire.map((data) => (data.prenom))} readOnly />
                    </div>
                    
                    <label htmlFor="oldPassword">Ancien mot de passe</label><br/>
                    <div style={{ position: 'relative' }}>
                        <input type={showOldPassword ? 'text' : 'password'} name="oldPassword" className="secretInputCmot" id="oldPassword" onChange={e => setOld(e.target.value)} />
                        <span onClick={toggleOldPasswordVisibility} style={{ position: 'absolute', right: '10px', top: '50%', transform: 'translateY(-50%)', cursor: 'pointer' }}>
                            <i className={showOldPassword ? 'fas fa-eye-slash' : 'fas fa-eye'}></i>
                        </span>
                    </div>
                    <br/>
                    <h4 className="text-danger">{info1}</h4>
                    <br/>
                    <label htmlFor="newPassword">Nouveau mot de passe</label><br/>
                    <div style={{ position: 'relative' }}>
                        <input type={showNewPassword ? 'text' : 'password'} name="newPassword" className="secretInputCmot" id="newPassword" onChange={e => setNew1(e.target.value)} />
                        <span onClick={toggleNewPasswordVisibility} style={{ position: 'absolute', right: '10px', top: '50%', transform: 'translateY(-50%)', cursor: 'pointer' }}>
                            <i className={showNewPassword ? 'fas fa-eye-slash' : 'fas fa-eye'}></i>
                        </span>
                    </div>
                    <br/>
                    <label htmlFor="confirmPassword">Confirmer mot de passe</label><br/>
                    <div style={{ position: 'relative' }}>
                        <input type={showConfirmPassword ? 'text' : 'password'} name="confirmPassword" className="secretInputCmot" id="confirmPassword" onChange={e => setNew2(e.target.value)} />
                        <span onClick={toggleConfirmPasswordVisibility} style={{ position: 'absolute', right: '10px', top: '50%', transform: 'translateY(-50%)', cursor: 'pointer' }}>
                            <i className={showConfirmPassword ? 'fas fa-eye-slash' : 'fas fa-eye'}></i>
                        </span>
                    </div>
                    <br/>
                    <h4 className="text-danger">{info2}</h4>
                    <br/>
                    <div className="secretSupD">
                        <Link onClick={handleModf}><button className='secretNm1'>Modifier</button></Link>
                        <Link onClick={toggleSup}><button className='secretNm2'>Annuler</button></Link>
                    </div>
                </div>
            </div>
        </div>)}
{supMod2 && (<div className='modal'>
    <div className='overlay'>
        
   
    <div className='modal-content'>
       
      <h2>Mot de passe modifier avec succès</h2>
      <div className='supD'>
     
      <Link onClick={toggleSup2}> <button className='supC' >QUITER</button> </Link>
    
      </div>
     
      </div>
    </div>
   
</div>)}
             
             <div className="hero"> 
             <h2 className="nom">{secretaire.map((data)=>(data.nom))} {secretaire.map((data)=>(data.prenom))}</h2>
             <h5 className="post"> SECRETAIRE  {secretaire.map((data)=>(data.auto_ecole))}</h5>
             </div>

             <div class="options">
     <h2 className="form-title2"> {secretaire.map((data)=>(data.nom))} {secretaire.map((data)=>(data.prenom))}</h2>           
    <button class="option-btn" onClick={e => setValid('on')}>Modifier</button>
    <button class="option-btn2" onClick={logout}>Déconnexion</button>
  </div>


             </div>
  


              
            <NavLink to={'/accueil'} style={({isActive})=>{ return{background: isActive? "gray": 'rgb(6, 122, 116)',};} } >   <button  className="pr"> Accueil </button></NavLink>
                <NavLink to={'/candidat'}  style={({isActive})=>{ return{background: isActive? "gray": 'rgb(6, 122, 116)',};} }   ><button className='pr'> Candidats </button></NavLink>
                <NavLink to={'/depense'} style={({isActive})=>{ return{background: isActive? "gray": 'rgb(6, 122, 116)',};} }   ><button className='pr'> Depenses </button></NavLink>
                <NavLink to={'/payement'} style={({isActive})=>{ return{background: isActive? "gray": 'rgb(6, 122, 116)',};} }   ><button className='pr'> Recettes </button></NavLink>
                <NavLink to={'/resultat'} style={({isActive})=>{ return{background: isActive? "gray": 'rgb(6, 122, 116)',};} }  ><button className='pr'> Resultats </button></NavLink>
                <Link onClick={logout} className="link">
                           <img src={log} className="am" />
                           <div className="deco">DECONNEXION</div>
                           
                      </Link>
                
    
            </div>
            </IconContext.Provider>
    
            
            </>
        )
       }else{

        //ici navOLd
        return(
        
            <>
            
            <IconContext.Provider value={{color:'#fff'}} >
            
                <div className="icon">
             <div>
             &nbsp;&nbsp;&nbsp;
             <img src={logo} className="userLog"  /> 
             </div>
             <div className="naI">
               {secretaire.map((data)=>(data.auto_ecole))} 
             </div> 
                   
            <div className="navbar">
            
                <Link to="#" className={sidebar?  "trois": "menu-bars"}>
                    <FaIcons.FaBars onClick={showSidebar} />
                </Link>
          
                &nbsp;&nbsp;&nbsp;
                  
    
            </div>
           
            
            <nav className={sidebar ? 'nav-menu active' : 'nav-menu'}>
                <ul className="nav-menu-items" >
                    <li className="navbar-toggle">
                        <Link to="#" className="menu-bars">
                           <AiIcons.AiOutlineClose onClick={showSidebar}/>
                        </Link>
                    </li>
                    <div className="carD" >
                    <div className="profil">
        <AiIcons.AiOutlineUser className="icone-profil" onClick={toggleMenu} />
     

        <div className="infos-utilisateur">
        <p className="inPo" onClick={toggleMenu} > {secretaire.map((data)=>(data.poste))} </p>
          <p className="inN" onClick={toggleMenu}> {secretaire.map((data)=>(data.nom))}  {secretaire.map((data)=>(data.prenom))} </p>
      
        </div>
      </div>

      {menuVisible && (
      <div className="dropdown-menu">
         <button className="option-btnMobi" onClick={e => setValid('on')}>Modifier</button>
         <button className="option-btnMobi2" onClick={logout}>Déconnexion</button>
      </div>
   )}
      
  
      </div>
                    {sidedata.map((item, index) =>{
                        const isActive = location.pathname === item.path;
                      return (
                       
                        <li key={index} className={isActive ? `${item.cName} active` : item.cName} onClick={showSidebar}>
                            <Link to={item.path}>
                                <span>{item.title}</span>
                             </Link>
                            
                        </li>
                        
                      
                      )

                    })}
                   <li className="nav-text">
                    <Link onClick={logout}  >
                         
                           <span>Deconnexion</span>
                           
                      </Link>
                      </li> 
                </ul>
            </nav>
            </div>
            </IconContext.Provider>
    

            {supMod && (<div className='secretModalSup'>
            <div className='secretOverlay'></div>
            <div className='secretModalContent'>
                <div className="secretContainerModal"> 
                    <h3 className="secretFormTitle">MODIFIER MOT DE PASSE</h3>
                    
                    <div className='secretSupD'>
                        <input type="text" name="nom" className="secretInputCmot" id="nom" value={secretaire.map((data) => (data.nom))} readOnly />
                        <input type="text" name="prenom" className="secretInputCmot" id="prenom" value={secretaire.map((data) => (data.prenom))} readOnly />
                    </div>
                    
                    <label htmlFor="oldPassword">Ancien mot de passe</label><br/>
                    <div style={{ position: 'relative' }}>
                        <input type={showOldPassword ? 'text' : 'password'} name="oldPassword" className="secretInputCmot" id="oldPassword" onChange={e => setOld(e.target.value)} />
                        <span onClick={toggleOldPasswordVisibility} style={{ position: 'absolute', right: '10px', top: '50%', transform: 'translateY(-50%)', cursor: 'pointer' }}>
                            <i className={showOldPassword ? 'fas fa-eye-slash' : 'fas fa-eye'}></i>
                        </span>
                    </div>
                    <br/>
                    <h4 className="text-danger">{info1}</h4>
                    <br/>
                    <label htmlFor="newPassword">Nouveau mot de passe</label><br/>
                    <div style={{ position: 'relative' }}>
                        <input type={showNewPassword ? 'text' : 'password'} name="newPassword" className="secretInputCmot" id="newPassword" onChange={e => setNew1(e.target.value)} />
                        <span onClick={toggleNewPasswordVisibility} style={{ position: 'absolute', right: '10px', top: '50%', transform: 'translateY(-50%)', cursor: 'pointer' }}>
                            <i className={showNewPassword ? 'fas fa-eye-slash' : 'fas fa-eye'}></i>
                        </span>
                    </div>
                    <br/>
                    <label htmlFor="confirmPassword">Confirmer mot de passe</label><br/>
                    <div style={{ position: 'relative' }}>
                        <input type={showConfirmPassword ? 'text' : 'password'} name="confirmPassword" className="secretInputCmot" id="confirmPassword" onChange={e => setNew2(e.target.value)} />
                        <span onClick={toggleConfirmPasswordVisibility} style={{ position: 'absolute', right: '10px', top: '50%', transform: 'translateY(-50%)', cursor: 'pointer' }}>
                            <i className={showConfirmPassword ? 'fas fa-eye-slash' : 'fas fa-eye'}></i>
                        </span>
                    </div>
                    <br/>
                    <h4 className="text-danger">{info2}</h4>
                    <br/>
                    <div className="secretSupD">
                        <Link onClick={handleModf}><button className='secretNm1'>Modifier</button></Link>
                        <Link onClick={toggleSup}><button className='secretNm2'>Annuler</button></Link>
                    </div>
                </div>
            </div>
        </div>)}
{supMod2 && (<div className='modal'>
    <div className='overlay'>
        
   
    <div className='modal-content'>
       
      <h2>Mot de passe modifier avec succès</h2>
      <div className='supD'>
     
      <Link onClick={toggleSup2}> <button className='supC' >QUITER</button> </Link>
    
      </div>
     
      </div>
    </div>
   
</div>)} 
    
            
            </>
        )
       }
    }else if(secretaire.map((data)=>(data.poste))=="moniteur") {
        if(largeurEcran>= 1000){
            return(
        
                <>
                
                
                <div className="barHaut">
    
                <div className="lep">
                    
                    
                    <img src={moni} className="user" onClick={showProfil} />

                    {supMod && (<div className='secretModalSup'>
            <div className='secretOverlay'></div>
            <div className='secretModalContent'>
                <div className="secretContainerModal"> 
                    <h3 className="secretFormTitle">MODIFIER MOT DE PASSE</h3>
                    
                    <div className='secretSupD'>
                        <input type="text" name="nom" className="secretInputCmot" id="nom" value={secretaire.map((data) => (data.nom))} readOnly />
                        <input type="text" name="prenom" className="secretInputCmot" id="prenom" value={secretaire.map((data) => (data.prenom))} readOnly />
                    </div>
                    
                    <label htmlFor="oldPassword">Ancien mot de passe</label><br/>
                    <div style={{ position: 'relative' }}>
                        <input type={showOldPassword ? 'text' : 'password'} name="oldPassword" className="secretInputCmot" id="oldPassword" onChange={e => setOld(e.target.value)} />
                        <span onClick={toggleOldPasswordVisibility} style={{ position: 'absolute', right: '10px', top: '50%', transform: 'translateY(-50%)', cursor: 'pointer' }}>
                            <i className={showOldPassword ? 'fas fa-eye-slash' : 'fas fa-eye'}></i>
                        </span>
                    </div>
                    <br/>
                    <h4 className="text-danger">{info1}</h4>
                    <br/>
                    <label htmlFor="newPassword">Nouveau mot de passe</label><br/>
                    <div style={{ position: 'relative' }}>
                        <input type={showNewPassword ? 'text' : 'password'} name="newPassword" className="secretInputCmot" id="newPassword" onChange={e => setNew1(e.target.value)} />
                        <span onClick={toggleNewPasswordVisibility} style={{ position: 'absolute', right: '10px', top: '50%', transform: 'translateY(-50%)', cursor: 'pointer' }}>
                            <i className={showNewPassword ? 'fas fa-eye-slash' : 'fas fa-eye'}></i>
                        </span>
                    </div>
                    <br/>
                    <label htmlFor="confirmPassword">Confirmer mot de passe</label><br/>
                    <div style={{ position: 'relative' }}>
                        <input type={showConfirmPassword ? 'text' : 'password'} name="confirmPassword" className="secretInputCmot" id="confirmPassword" onChange={e => setNew2(e.target.value)} />
                        <span onClick={toggleConfirmPasswordVisibility} style={{ position: 'absolute', right: '10px', top: '50%', transform: 'translateY(-50%)', cursor: 'pointer' }}>
                            <i className={showConfirmPassword ? 'fas fa-eye-slash' : 'fas fa-eye'}></i>
                        </span>
                    </div>
                    <br/>
                    <h4 className="text-danger">{info2}</h4>
                    <br/>
                    <div className="secretSupD">
                        <Link onClick={handleModf}><button className='secretNm1'>Modifier</button></Link>
                        <Link onClick={toggleSup}><button className='secretNm2'>Annuler</button></Link>
                    </div>
                </div>
            </div>
        </div>)}
{supMod2 && (<div className='modal'>
    <div className='overlay'>
        
   
    <div className='modal-content'>
       
      <h2>Mot de passe modifier avec succès</h2>
      <div className='supD'>
     
      <Link onClick={toggleSup2}> <button className='supC' >QUITER</button> </Link>
    
      </div>
     
      </div>
    </div>
   
</div>)}
                    
                    <div className="hero"> 
                    <h2 className="nom">{secretaire.map((data)=>(data.nom))} {secretaire.map((data)=>(data.prenom))}</h2>
                    <h5 className="post"> MONITEUR  {secretaire.map((data)=>(data.auto_ecole))}</h5>
                    </div>
       

                    <div class="options">
     <h2 className="form-title2"> {secretaire.map((data)=>(data.nom))} {secretaire.map((data)=>(data.prenom))}</h2>           
    <button class="option-btn" onClick={e => setValid('on')}>Modifier</button>
    <button class="option-btn2" onClick={logout}>Déconnexion</button>
  </div>
       

                    </div>
                    
              
                <NavLink to={'/accueil'} style={({isActive})=>{ return{background: isActive? "gray": 'rgb(6, 122, 116)',};} }><button className="pr"> Accueil</button> </NavLink>
                <NavLink to={'/candidatMoni'} style={({isActive})=>{ return{background: isActive? "gray": 'rgb(6, 122, 116)',};} }><button className="pr"> Candidats </button></NavLink>
                <NavLink to={'/presence'} style={({isActive})=>{ return{background: isActive? "gray": 'rgb(6, 122, 116)',};} }> <button className="pr">Presence </button></NavLink>
                    
                    <NavLink to={'/moni'} style={({isActive})=>{ return{background: isActive? "gray": 'rgb(6, 122, 116)',};} }><button className="pr"> Resultats</button> </NavLink>
                    
                    <Link onClick={logout} className="link">
                               <img src={log} className="am" />
                               <div className="deco">DECONNEXION</div>
                               
                          </Link>
        
                </div>
               
                
                </>
            )
        }else{
            
            return(
        
                <>
                
                <IconContext.Provider value={{color:'#fff'}} >
                
                    <div className="icon">
                 <div>
                 &nbsp;&nbsp;&nbsp;
                 <img src={logo} className="userLog"  /> 
                 </div>
                       
                 <div className="naI">
               {secretaire.map((data)=>(data.auto_ecole))} 
             </div>
                <div className="navbar">
                
                    <Link to="#" className={sidebar?  "trois": "menu-bars"}>
                        <FaIcons.FaBars onClick={showSidebar} />
                    </Link>
              
                    &nbsp;&nbsp;&nbsp;
                      
        
                </div>
                <nav className={sidebar ? 'nav-menu active' : 'nav-menu'}>
                    <ul className="nav-menu-items" >
                        <li className="navbar-toggle">
                            <Link to="#" className="menu-bars">
                               <AiIcons.AiOutlineClose onClick={showSidebar}/>
                            </Link>
                        </li>
                        <div className="carD">
                    <div className="profil">
        <AiIcons.AiOutlineUser className="icone-profil" onClick={toggleMenu}/>
        <div className="infos-utilisateur">
        <p className="inPo" onClick={toggleMenu}> {secretaire.map((data)=>(data.poste))} </p>
          <p className="inN" onClick={toggleMenu}> {secretaire.map((data)=>(data.nom))}  {secretaire.map((data)=>(data.prenom))} </p>
      
        </div>
      </div>

      {menuVisible && (
      <div className="dropdown-menu">
         <button className="option-btnMobi" onClick={e => setValid('on')}>Modifier</button>
         <button className="option-btnMobi2" onClick={logout}>Déconnexion</button>
      </div>
   )}
      

      </div>
                        {sidedataMoni.map((item, index) =>{
                            const isActive = location.pathname === item.path;
                          return (
                           
                          <li key={index} className={isActive ? `${item.cName} active` : item.cName} onClick={showSidebar}>
                               <Link to={item.path}>
                                    <span>{item.title}</span>
                                 </Link>
                                
                            </li>
                            
                          
                          )
    
                        })}
                       <li className="nav-text">
                        <Link onClick={logout}  >
                             
                               <span>Deconnexion</span>
                               
                          </Link>
                          </li> 
                    </ul>
                </nav>


                </div>
                </IconContext.Provider>
        

                {supMod && (<div className='secretModalSup'>
            <div className='secretOverlay'></div>
            <div className='secretModalContent'>
                <div className="secretContainerModal"> 
                    <h3 className="secretFormTitle">MODIFIER MOT DE PASSE</h3>
                    
                    <div className='secretSupD'>
                        <input type="text" name="nom" className="secretInputCmot" id="nom" value={secretaire.map((data) => (data.nom))} readOnly />
                        <input type="text" name="prenom" className="secretInputCmot" id="prenom" value={secretaire.map((data) => (data.prenom))} readOnly />
                    </div>
                    
                    <label htmlFor="oldPassword">Ancien mot de passe</label><br/>
                    <div style={{ position: 'relative' }}>
                        <input type={showOldPassword ? 'text' : 'password'} name="oldPassword" className="secretInputCmot" id="oldPassword" onChange={e => setOld(e.target.value)} />
                        <span onClick={toggleOldPasswordVisibility} style={{ position: 'absolute', right: '10px', top: '50%', transform: 'translateY(-50%)', cursor: 'pointer' }}>
                            <i className={showOldPassword ? 'fas fa-eye-slash' : 'fas fa-eye'}></i>
                        </span>
                    </div>
                    <br/>
                    <h4 className="text-danger">{info1}</h4>
                    <br/>
                    <label htmlFor="newPassword">Nouveau mot de passe</label><br/>
                    <div style={{ position: 'relative' }}>
                        <input type={showNewPassword ? 'text' : 'password'} name="newPassword" className="secretInputCmot" id="newPassword" onChange={e => setNew1(e.target.value)} />
                        <span onClick={toggleNewPasswordVisibility} style={{ position: 'absolute', right: '10px', top: '50%', transform: 'translateY(-50%)', cursor: 'pointer' }}>
                            <i className={showNewPassword ? 'fas fa-eye-slash' : 'fas fa-eye'}></i>
                        </span>
                    </div>
                    <br/>
                    <label htmlFor="confirmPassword">Confirmer mot de passe</label><br/>
                    <div style={{ position: 'relative' }}>
                        <input type={showConfirmPassword ? 'text' : 'password'} name="confirmPassword" className="secretInputCmot" id="confirmPassword" onChange={e => setNew2(e.target.value)} />
                        <span onClick={toggleConfirmPasswordVisibility} style={{ position: 'absolute', right: '10px', top: '50%', transform: 'translateY(-50%)', cursor: 'pointer' }}>
                            <i className={showConfirmPassword ? 'fas fa-eye-slash' : 'fas fa-eye'}></i>
                        </span>
                    </div>
                    <br/>
                    <h4 className="text-danger">{info2}</h4>
                    <br/>
                    <div className="secretSupD">
                        <Link onClick={handleModf}><button className='secretNm1'>Modifier</button></Link>
                        <Link onClick={toggleSup}><button className='secretNm2'>Annuler</button></Link>
                    </div>
                </div>
            </div>
        </div>)}
{supMod2 && (<div className='modal'>
    <div className='overlay'>
        
   
    <div className='modal-content'>
       
      <h2>Mot de passe modifier avec succès</h2>
      <div className='supD'>
     
      <Link onClick={toggleSup2}> <button className='supC' >QUITER</button> </Link>
    
      </div>
     
      </div>
    </div>
   
</div>)} 
        
                
                </>
            )

        }
       
    }else if(secretaire.map((data)=>(data.poste))=="directeur"){



        return(
        
            <>
            
            
            <div className="barHaut">

            <Link to={'/accueil'} className="lin"> Accueil </Link>
            <Link to={'/candidatDir'} className="lin"> Candidats </Link>
                <Link to={'/depenseDir'} className="lin"> Depenses </Link>
                <Link to={'/depense2Dir'} className="lin"> Mes depenses </Link>
                
                <Link to={'/payementDir'} className="lin"> Recettes </Link>
                <Link to={'/bilan'} className="lin"> Bilan Global </Link>
                <Link to={'/resultatDir'} className="lin"> Resultats </Link>
                
                
                <div className="lep">
                    <div className="hero">
                <h2 className="nom">{secretaire.map((data)=>(data.nom))} {secretaire.map((data)=>(data.prenom))}</h2>
                <h5> DIRECTEUR  {secretaire.map((data)=>(data.auto_ecole))}</h5>
                </div>
                <img src={moni} className="user" onClick={showProfil} />
                
                </div>
                <div className={profil ? 'sub-menu-wrap open':'sub-menu-wrap' } id="subMenu">
                    <div className="sub-menu">
                        <div className="user-info">
                            <img src={moni} className="if"></img>
                            <h2>{secretaire.map((data)=>(data.nom))} {secretaire.map((data)=>(data.prenom))}</h2>
                        </div>
                        <hr></hr>
    
                         <Link to="/" className="link">
                              <img src={log} className="am" />
                              <p>DECONNEXION</p>
                              <span>{'>'}</span>
                         </Link>
    
                    </div>
                </div>     
    
            </div>

    
            
            </>
        )




    }else{
        return(
            <>
            
            
            <div className="barHaut">

            
                
                
                <div className="lep">
                    <div className="hero">
                 </div>
                 </div>
               
    
            </div>

    
            
            </>
        )
    }
      }
    