import React, {useEffect, useState } from "react"
import axios from "axios";
import { Link, useNavigate, useParams } from "react-router-dom";
import Loader from "F:/projet/first/src/components/loader";
import moni from 'F:/projet/first/src/components/image/moni.png';
import croix from 'F:/projet/first/src/components/image/croix.png'
import moment from "moment";
import Cookies from 'js-cookie';
import { FaEye, FaEyeSlash } from 'react-icons/fa';
import Modal from "./popup";
import ErrorModal from "./errorpopup";
import secre from 'F:/projet/first/src/components/image/images.png'
export default function Profil(){
  const headers = {
    'Content-Type': 'application/json',
    'Access-Control-Allow-Origin': 'https://gestauto.seg.solutions',
    'credentials': 'include',
  };
         const [query, setQuery]= useState(""); 

         const [titre,setTitre]= useState(Cookies.get('titre'))
         const [vtoken, setVtoken]= useState(Cookies.get('token'))
         const [gestauto,setGestauto]= useState(Cookies.get('gestauto'))
         const [dure, setDure]= useState('')
         const [langue, setLangue]= useState('')
         const [le, setLe]= useState('')
         const [name, setName]= useState('')
         const [prenom, setPrenom]= useState('')
         const [username,setUsername ]= useState('')
         const [password,setPassword ]= useState('')
         const [total, setTotal]= useState('')
         const [contact, setContact]= useState('')
         const [sexe, setSexe]= useState('')
         const [adresse, setAdresse]= useState('')
         const [age,setAge]= useState('')
         const [montant, setMontant]= useState('')
         const [statut, setStatut]= useState('')
         const [permis, setPermis]= useState('')
         const [date, setDate]= useState('')
         const [dateRetrait, setDateRetrait]= useState('')
         const [nombre, setNombre]= useState('')
         const [nombre2, setNombre2]= useState('')
         const {id}= useParams();
         const [loginStatut, setLoginStatut]= useState('')
         const navigate = useNavigate();
         const [payement, setPayement]= useState([])
         const [error, setError] = useState(null);
         const [loader, setLoader]= useState(true)
         const [showPassword, setShowPassword] = useState(false);
         const [secretaire, setSecretaire] = useState([])
         const [categorie, setCategorie] = useState('')
         const [image, setImage]= useState([])
         const [viewLoader, setViewLoader]= useState(false)
         const togglePasswordVisibility = () => {
          setShowPassword(!showPassword);
        };







        const handleDelete = async (candidatId) => {
          try {
            const response = await fetch(`https://gestauto-56bfcbe4b876.herokuapp.com/deleteCandidat20/${candidatId}`, {
              method: 'DELETE',
              headers: {
             
                'Content-Type': 'application/json',
              },
            });
        
            if (response.ok) {
              setViewLoader(false)
              navigate(-1); 
            } else {
              const data = await response.json();
              alert(`Erreur: ${data.message}`);
            }
          } catch (error) {
            console.error("Erreur lors de la suppression :", error);
            alert("Une erreur est survenue.");
          }
        };
        



        const [showConfirm, setShowConfirm] = useState(false);

        // Fonction pour gérer la confirmation
        const confirmDelete = () => {
          setViewLoader(true)
          setShowConfirm(false);
          handleDelete(id); // Si confirmé, on supprime le candidat
        };
           




         
  useEffect(()=>{
      axios.get('https://gestauto-56bfcbe4b876.herokuapp.com/navbar?id='+gestauto+'&titre='+titre+'', { headers })
      .then(res => setSecretaire(res.data))
      .catch(err => console.log(err));
  }, [])
  var effet
  var ob
  
  let drop = ''
  if(secretaire.map((data)=>(data.poste))=='secretaire' || secretaire.map((data)=>(data.poste))=='directeur' ){
    effet=<Link to={'/retrait/'+le} className='linko'><button className="retrait">MODIFIER</button> </Link>
    
  }else{effet=''}
 
  if(secretaire.map((data)=>(data.poste))=='directeur'){
    ob=<Link to={'/lesObservationsDir/'+le} className="linko"><button className="retrait">OBSERVATION</button></Link> 
    drop=   <button className="prsp" onClick={() => setShowConfirm(true)}>Supprimer</button>
  
  }else{
    ob=<Link to={'/lesObservations/'+le} className="linko"><button className="retrait">OBSERVATION</button></Link> 
  }
     
         const [candidat, setCandidat] = useState([])
         useEffect(()=>{
             axios.get('https://gestauto-56bfcbe4b876.herokuapp.com/update/'+id, { headers })
             .then(res => { 
                 setLe(res.data[0].id)
                 setName(res.data[0].nom)
                 setPrenom(res.data[0].prenom)
                 setContact(res.data[0].contact)
                 setSexe(res.data[0].sexe)
                 setAdresse(res.data[0].adresse)
                 setAge(res.data[0].age)
                 setMontant(res.data[0].montant_payer)
                 setStatut(res.data[0].statut_payement)
                 setDate(res.data[0].date)
                 setPermis(res.data[0].statut_retrait_permis)
                 setDateRetrait(res.data[0].date_retrait)
                 setCandidat(res.data)
                 setTotal(res.data[0].montant_total)
                 setUsername(res.data[0].user_name)
                 setPassword(res.data[0].password)
                 setDure(res.data[0].dure)
                 setLangue(res.data[0].langue)
                 setCategorie(res.data[0].categorie)
                 setImage(res.data[0].image)
                setLoader(false)
             }
    
                 
                 )
             .catch(err => {
              console.log(err)
              setError(err)
              setLoader(false)
              
            });
     
         
         }, [])
       
     
     const[modal, setModal]= useState(false) 
const toggleModal = ()=> {
    setModal(!modal)
}
 
       

const [isModalOpen, setIsModalOpen] = useState(false);
const [currentId, setCurrentId] = useState(null);
const [fileInputId, setFileInputId] = useState(null); // Pour stocker l'ID du champ de fichier

const [isErrorModalOpen, setIsErrorModalOpen] = useState(false);



       // Fonction pour ouvrir la boîte de dialogue d'importation de fichier
       function handleImportClick2(id) {
        const fileInput = document.getElementById(`file-input-${id}`);
        if (fileInput) {
          fileInput.click();
        } else {
          console.error(`File input with id 'file-input-${id}' not found`);
        }
      }
     
     // Fonction pour gérer l'upload de fichier
     function handleImageUpload(event, id) {
      setViewLoader(true)
       const file = event.target.files[0];
       if (file) {
         const formData = new FormData();
         formData.append('image', file);
         formData.append('id', id);
     
         
         axios.post('https://gestauto-56bfcbe4b876.herokuapp.com/upload-image', formData)
           .then(response => {
            setTimeout(() => {
             
              window.location.reload(); // Recharger la page après 3 secondes
            }, 100);
           
           })
           .catch(error => {
             console.error("Erreur lors de l'upload de l'image:", error);
           });
       }
     }
     




       

// Détecter si l'utilisateur est sur iOS
const isIOS = /iPhone|iPad|iPod/i.test(navigator.userAgent);




// Fonction pour ouvrir la caméra sur iOS
function openCameraIOS2(id) {
  if (isIOS) {
    const fileInput = document.createElement('input');
    fileInput.type = 'file';
    fileInput.accept = 'image/*;capture=camera'; // Ouvre directement l'appareil photo sur iOS
    fileInput.style.display = 'none'; // Cacher l'input file visuellement

    // Gérer la sélection d'une image via l'appareil photo
    fileInput.onchange = function(event) {
      const file = event.target.files[0];
      if (file) {
        setViewLoader(true)
        const formData = new FormData();
        formData.append('image', file, 'profile-image.jpg');
        formData.append('id', id); // Remplacez par votre ID de candidat

        axios.post('https://gestauto-56bfcbe4b876.herokuapp.com/upload-image', formData)
          .then(response => {
            setTimeout(() => {
            
              console.log('Image uploadée avec succès');
              window.location.reload(); // Recharger la page après 3 secondes
            }, 200);
          
          })
          .catch(error => console.error('Erreur lors de l\'upload:', error));
      }
    };

    // Ajouter le champ input au document et le déclencher
    document.body.appendChild(fileInput);
    fileInput.click(); // Simuler un clic pour ouvrir la caméra
  }
}






     
     
var permisInfo
var laDate
if(permis=='oui'){
  permisInfo='Retiré'
  laDate=<div className="adro">
  <div >LE</div> <div>{moment(dateRetrait).utc(1).format('DD-MM-YYYY')}</div>
  </div>
}else{
    permisInfo='Non retiré'
}
let cata 
if(categorie){
  cata=<> <div className="adro">
  <div >Categorie</div> <div>Permis {categorie}</div>
  </div><br/></>
}else{
  cata= ''
}



let load

        if(viewLoader==true){
            load=   <div className="avt">
            <div className="rin">
              <div className="chm">
                <Loader/>
              </div>
            </div>
          </div>
        }else{
            load=''
        }


if(loader){
  return(
      <div className="per">
      
      <div className="loader">
      <div className="overlayLo"> 
          <div className="loaderP">
          <Loader/>
          </div>
          </div>
          </div>
          </div>
  )
}else if(error){
  return (<div>Une erreur est survenue : {error.message}</div>)
}else{

    
    return(


        <>
        <div className="pofille">
       <div className="box">
        <br/>
        {load}
       <div className="playProfil"> 
       <div className="image-container8">
<img src={image ? `${image}` : (sexe === 'Masculin' ? moni : secre)} alt="Candidat" className="profile-image" />
<div className="camera-icon60" onClick={ () => isIOS ? openCameraIOS2(id) : handleImportClick2(id) }>
              📷
            </div>
          </div>
               
          <input
           type="file"
           id={`file-input-${id}`}
           style={{ display: 'none' }}
         onChange={(event) => handleImageUpload(event, id)}
         />    
                   
<Link  onClick={toggleModal} >     <h5 className="osi">  {name} {prenom} </h5></Link>

{drop}


{modal && (<div className='modal'>
    <div className='overlay'>
        
    </div>
    <div className='modal-content'>
        <div> <h3 className="useName">USERNAME :</h3> {username}<br/>
        <h3 className="useName">PASSWORD :</h3> {password}
        </div>
      <Link onClick={toggleModal}>  <img src={croix} className='close-modal'/></Link>
    </div>
</div>)}

{showConfirm && (
  <div className="dropeModal-overlay">
    <div className="dropeModal-content">
      <p>Êtes-vous sûr de vouloir supprimer ce candidat ?</p>
      <button className="dropeModal-button dropeModal-button-confirm" onClick={confirmDelete}>
        Confirmer
      </button>
      <button className="dropeModal-button dropeModal-button-cancel" onClick={() => setShowConfirm(false)}>
        Annuler
      </button>
    </div>
  </div>
)}
                   </div>
                   
                   <br/>
                  
                   <div className="adro">
                  <div > SEXE</div> <div>{sexe}</div>
                  </div>
                  <br/>
                 
                   <div className="adro">

                 <div className="tes">  ADRESSE</div> <div className="tes">{adresse}</div>
                   </div>
                   <br/>
                   <div className="adro">
                  <div > CONTACT</div> <div>{contact}</div>
                  </div>
                  <br/>
                  
                  <div className="adro">
                  <div > INSCRIT</div> <div>{moment(date).utc(1).format('DD-MM-YYYY')}</div>
                  </div>
                  <div></div>
                  <br/>
                  {cata}
                  
                  <div className="adro">
                  <div > Duré de la formation</div> <div>{dure ? dure.substring(0, 2) : ''} mois</div>
                  </div>
                  <br/>
                  <div className="adro">
                  <div > Langue</div> <div>{langue}</div>
                  </div>
                  <br/>
                  <div className="adro">
                  <div > USERNAME</div> <div>{username}</div>
                  </div>
                  <br/>
                  <div className="adro">
                  <div > PASSWORD</div> <div>{showPassword ? password : '*'.repeat(password.length)}
               <span>   {showPassword ? (
        <FaEyeSlash onClick={togglePasswordVisibility} />
      ) : (
        <FaEye onClick={togglePasswordVisibility} />
      )}</span>
                  </div>
                  </div>
                  <br/>
                  <div className="adro">
                  <div >PERMIS </div> <div>{permisInfo}</div>
                  </div>
                  <br/>
                  {laDate}
                
                  <div className="poBut">
                  {ob}
                
                  {effet}
                  </div>
                   </div>
                   </div>
        </>
    )
}
}